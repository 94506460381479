import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { updateAccountMarketplaceStatus } from "./AccountsMarketplaces.api";


const AccountMarketplaceStatusUpdateModal: React.FC = () => {
    const dispatch = useDispatch();
    const modalValues = useSelector((state: StoreInterface) =>
        state.accountsMarketplacesPage.accountMarketplaceStatusUpdateModal
    );
    const accountsMarketplacesTableData = useSelector((state: StoreInterface) =>
        state.accountsMarketplacesPage.accountsMarketplacesTableData
    );

    const getNewState = () => {
        return accountsMarketplacesTableData.map((data) => {
            if (data.accountMarketplaceId === modalValues.accountMarketplaceId) {
                return {
                    ...data,
                    status: modalValues.status
                }
            }
            return data;
        })
    }

    const onSubmit = () => {
        updateAccountMarketplaceStatus(modalValues)
            .then((response) => {
                if (response && response.status === 200) {
                    dispatch({ type: 'resetAccountMarketplaceStatusUpdateModalValues' });
                    dispatch({
                        type: 'setAccountsMarketplacesTableData',
                        payload: getNewState(),
                    });
                }
            })
    }

    return (
        <ConfirmationDialog
            title="Confirm MP subscription status change"
            isOpen={modalValues.showModal}
            cancelButtonLabel="Cancel"
            submitButtonLabel="Confirm"
            modalContentStyle={{ width: '51rem' }}
            handleModalCancel={() => {
                dispatch({ type: 'resetAccountMarketplaceStatusUpdateModalValues' });
            }}
            handleModalSubmit={onSubmit}
        >
            Please confirm that you want to change your marketplace subscription status.
            Select 'Confirm' to proceed or 'Cancel' to return to the previous screen.
        </ConfirmationDialog>
    )
}

export default AccountMarketplaceStatusUpdateModal;
