import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import * as R from 'ramda';

import {
  getAccountsConf,
  getCountriesStates,
  getOneContract,
  getPaymentInfoConfig,
} from '../contractsPage.utils';
import * as S from '../../rootPage/RootPage.style';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import TitleSection from './titleSection/TitleSection';
import InfoSection from './infoSection/InfoSection';
import SmAccountsSection from './accountsSection/SmAccountsSection';

const ContractPage: React.FC = () => {
  const dispatch = useDispatch();
  const { id: contractId } = useParams<{id: string}>();
  const newContract = useRouteMatch('/contracts/new') || false;
  const accountsConfiguration = useSelector(
    (state: StoreInterface) => state.contractsPage.accountsConfiguration
  );
  const loadingContract = useSelector(
    (state: StoreInterface) => state.contractPage.loadingContract
  );

  useEffect(() => {
    dispatch({ type: 'resetContractInfo' });
  }, []); // eslint-disable-line

  useEffect(() => {
    const callFun = async () => {
      if (accountsConfiguration.cloudContractStatuses.length <= 1) {
        getAccountsConf(dispatch);
      }

      if (R.isEmpty(accountsConfiguration.countriesStates)) {
        getCountriesStates(dispatch);
      }

      if (R.isEmpty(accountsConfiguration.paymentInfoConfig)) {
        getPaymentInfoConfig(dispatch);
      }

      if (contractId) {
        await getOneContract(contractId, dispatch);
      }
    };

    if (newContract) {
      dispatch({ type: 'resetContractInfo' });
    }

    callFun();
  }, []); // eslint-disable-line

  if (loadingContract) return (<S.LoadingWrapper>
      Loading... Please wait.
  </S.LoadingWrapper>);

  return (
      <>
          <TitleSection />

          <InfoSection />

          <SmAccountsSection />
      </>
  );
};

export default ContractPage;
