import React from 'react';
import { Button, IconNamesEnum, SvgIcon } from '@redislabsdev/redislabs-ui-components';
import { PaymentMethodDropdownMenu } from '@redislabsdev/redislabs-ui-components/ui/components/DropdownMenu';
import * as S from '../../contractsPage/contractPage/infoSection/infoSection.style';

const McpRenderAnAccount = (props) => {
  const {
    account: { accountId },
    account,
    view,
    accDefaultPayOption,
    onRemoveAccount,
  } = props;

 return(
   <S.SpanDisplayFlexInline>
     <S.SMAccountsNewLine>
       <S.AccName data-testid="accountId">{accountId}</S.AccName>
     </S.SMAccountsNewLine>
     <S.SMAccountsNewLine>
       <S.AccName data-testid="accountName">{account.accountName}</S.AccName>
     </S.SMAccountsNewLine>
     <S.SMAccountsNewLine
       view={view}
     >
       <PaymentMethodDropdownMenu
         dataTestIdSuffix="marketplaceContract-paymentMethod"
         items={[accDefaultPayOption]}
         selectedItem={accDefaultPayOption}
         disabled
       />
       {!view && (
         <Button
           size="small"
           variant="link"
           data-testid="button--remove-account"
           onClick={() => onRemoveAccount(accountId)}
         >
           <SvgIcon width="20" height="20" name={IconNamesEnum.DELETE} fill="blue6" />
         </Button>
                   )}
     </S.SMAccountsNewLine>
   </S.SpanDisplayFlexInline>
 );
};

export default McpRenderAnAccount;
