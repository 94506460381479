import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment, { Moment } from 'moment';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';

import * as R from 'ramda';
import * as CS from '../../../styles/common.style';
import { graceContract } from '../contractsPage.utils';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import { utcDateStartOfDay } from '../../../utils/common.utils';
import { defaultGraceContractInfo } from '../../../constants/contractsPageConstants';
import * as S from './GraceContractPopup.style';

const GraceContractPopup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const graceContractModal = useSelector(
    (state: StoreInterface) => state.contractsPage.graceContractModalData
  );

  const { contractId, endDate, selectedEndDate } = graceContractModal;
  const [currentMonth, setCurrentMonth] = useState<Moment>(endDate || utcDateStartOfDay);

  useEffect(() => {
    if (endDate) {
      setCurrentMonth(endDate as Moment);
    }
  }, [endDate]);

  return (
      <ConfirmationDialog
          title="Grace Period"
          isOpen={!R.isEmpty(graceContractModal.contractId)}
          cancelButtonLabel="Cancel"
          submitButtonLabel="Update"
          handleModalCancel={() => {
        dispatch({
          type: 'setGraceContractModalData',
          payload: defaultGraceContractInfo,
        });
      }}
          handleModalSubmit={() => {
        graceContract(contractId, selectedEndDate?.endOf('day').valueOf(), () => {
          dispatch({
            type: 'setGraceContractModalData',
            payload: defaultGraceContractInfo,
          });
          history.push(`/contracts/${contractId}/view`);
        });
      }}
    >
          <S.GracePopupContainer>
              <S.UpdateEndDateWrapper>
                  <span>
                      Update end date:
                  </span>
                  <S.EndDateSpan>
                      {selectedEndDate?.format('DD/M/YY') || endDate?.format('DD/M/YY')}
                  </S.EndDateSpan>
              </S.UpdateEndDateWrapper>
              <CS.DatePickerWrapper
                  currentMonth={currentMonth?.toDate()}
                  setCurrentMonth={(newMonth) => {
            setCurrentMonth(moment(newMonth));
          }}
                  disabledDays={{
            before: endDate?.toDate(),
          }}
                  currentDay={selectedEndDate?.toDate() || endDate?.toDate()}
                  setCurrentDay={(newDate) => {
            dispatch({
              type: 'setGraceContractModalData',
              payload: {
                ...graceContractModal,
                selectedEndDate: moment.utc(newDate).startOf('day'),
              },
            });
          }}
        />
          </S.GracePopupContainer>
      </ConfirmationDialog>
  );
};

export default GraceContractPopup;
