import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import * as CS from '../../../styles/common.style';
import { MARKETPLACE_CONTRACT_WRITE } from '../../../constants/permissionsConstants';
import { denyBasedOnStatus, disableBasedOnStatus } from '../../contractsPage/contractPage/ContractPage.utils';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import TableStatus from '../../../components/TableStatus/TableStatus';
import * as S from '../../contractsPage/contractPage/ContractPage.style';
import {
  getExpandMarketplaceContractId,
  getMarketplaceContracFormTouchedState,
  getMarketplaceContractAccountsOnEdit,
  getMarketplaceContractAccountsUpdateInProgress,
  getMarketplaceContractComment,
  getMarketplaceContractCredit,
  getMarketplaceContractCustomerEmail,
  getMarketplaceContractEnableAccountsOnEdit,
  getMarketplaceContractInfoValidations,
  getMarketplaceContractRedislabsEmailList,
  getMarketplaceContractStatus
} from '../../../selectors/MarketplaceContractPageSelectors';
import { saveMarketplaceContract } from './MarketplaceContract.api';

const McpTitleSection: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const view = useRouteMatch('/marketplaceContracts/:id/view') || false;

  const marketplaceContractsStatuses = useSelector(
    (state: StoreInterface) => state.marketplaceContracts.marketplaceContractsStatuses
  );
  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);
  const status = useSelector(getMarketplaceContractStatus);
  const expandContractId = useSelector(getExpandMarketplaceContractId);
  const redislabsEmailList = useSelector(getMarketplaceContractRedislabsEmailList);
  const customerEmail = useSelector(getMarketplaceContractCustomerEmail);
  const credit = useSelector(getMarketplaceContractCredit);
  const comment = useSelector(getMarketplaceContractComment);
  const accountsOnEdit = useSelector(getMarketplaceContractAccountsOnEdit);
  const enableAccountsEdit = useSelector(getMarketplaceContractEnableAccountsOnEdit);
  const accountsUpdateInProgress = useSelector(getMarketplaceContractAccountsUpdateInProgress);
  const marketplaceFormTouchedState = useSelector(getMarketplaceContracFormTouchedState);
  const marketplaceContractInfoValidation = useSelector(getMarketplaceContractInfoValidations);

  const { id: contractId } = useParams<{ id: string }>();

  const redirectToMarketplaceContractsPage = (id: number | boolean) => {
    dispatch({ type: 'resetMarketplaceContractInfo' });

    if (id) {
      return history.push(`/marketplaceContracts/${id}/view`);
    }

    if (view) {
      return history.push('/marketplaceContracts');
    }

    return history.goBack();
  };

  const buildExpandedMarketplaceContractLink = (id: number) => {
    return `/marketplaceContracts/${id}/view`;
  }

  const ableToSave =
    marketplaceFormTouchedState &&
    marketplaceContractInfoValidation.customerEmail &&
    marketplaceContractInfoValidation.credit &&
    marketplaceContractInfoValidation.redislabsEmailList &&
    marketplaceContractInfoValidation.accountsOnEdit;

  const handleSaveContract = () => {
    const accounts = enableAccountsEdit && !accountsUpdateInProgress ? accountsOnEdit : null;

    saveMarketplaceContract({
      reqParams: { customerEmail, redislabsEmailList, credit, comment, accounts },
      contractId,
      callBack: () => redirectToMarketplaceContractsPage(Number(contractId)),
      dispatch,
    });
  };

  const renderCancelButton = () => (
    <Button
      variant="ghost"
      data-testid="button--cancel"
      onClick={() => redirectToMarketplaceContractsPage(false)}
    >
      Cancel
    </Button>
  );

  const renderExpandContractLink = () => {
    if (expandContractId) {
      return (
        <span>(
          <S.Link href={buildExpandedMarketplaceContractLink(expandContractId)}>
            {`New Contract ID ${expandContractId}`}</S.Link>
          )
        </span>
      )
    }
  }

  const renderSaveButton = () => (
    <Button data-testid="button--save-contract" onClick={handleSaveContract} disabled={!ableToSave}>
      Save Contract
    </Button>
  );

  const renderEditButton = () => {
    const canWriteContracts = permissions.includes(MARKETPLACE_CONTRACT_WRITE);
    const denyEditBasedOnStatus = denyBasedOnStatus(status); // used to remove the button on
    // various statuses

    if (!canWriteContracts || denyEditBasedOnStatus) return null;

    return (
      <Button
        data-testid="button--edit-contract"
        disabled={disableBasedOnStatus(status)}
        onClick={() => history.push(`/marketplaceContracts/${contractId}/edit`)}
      >
        Edit Contract
      </Button>
    );
  };

  const renderButtons = () => {
    return (
      <>
        <CS.JustifyToLeft>
          <div>
            <CS.PageHeaderTitle>
              {`Marketplace contract ID ${contractId}`}
            </CS.PageHeaderTitle>
          </div>
          <S.TitleStatusWrapper>
            <TableStatus
              status={status}
              statuses={marketplaceContractsStatuses}
              customStatusWidth="100%"
              dataTestId="contract-status"
            />
          </S.TitleStatusWrapper>
          {renderExpandContractLink()}
        </CS.JustifyToLeft>
        <CS.JustifyToRight>
          {renderCancelButton()}
          {view ? renderEditButton() : renderSaveButton()}
        </CS.JustifyToRight>
      </>
    );
  };

  return (<CS.PageTitleAndActions>
    {renderButtons()}
  </CS.PageTitleAndActions>);
};

export default McpTitleSection;
