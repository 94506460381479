export const contractPageDefaultState = {
  wireTransferFormData: {
    accountAddress: {
      address1: '',
      address2: '',
      city: '',
      zip: '',
      countryCode: '',
      stateCode: '',
      vatCode: '',
      companyTaxId: '',
    },
    billingAddress: {
      billAddress1: '',
      billAddress2: '',
      billCity: '',
      billZip: '',
      billCountryCode: '',
      billStateCode: '',
    },
  },
  wireTransferDataValidation: {
    companyTaxId: true,
  },
  wireTransferOptions: {
    viewType: '',
    showModal: false,
    wireForContractId: null,
    sameAccAddAsBill: true,
    paymentInfoId: null,
  },
  activeProSubsAccountIds: [],
  newWireTransferId: 0,
  loadingContract: false,
  showViewUsageReportModal: false,
};

// TODO: add correct interface + fix dependency

export default function contractPageReducer(state, action) {
  const { contractPage } = state;

  switch (action.type) {
    case 'setLoadingContract':
      return {
        ...state,
        contractPage: {
          ...contractPage,
          loadingContract: action.payload,
        },
      };

    case 'wireTransferFormData': {
      const { wireTransferFormData } = contractPage;

      return {
        ...state,
        contractPage: {
          ...contractPage,
          wireTransferFormData: {
            ...wireTransferFormData,
            ...action.payload,
          },
        },
      };
    }

    case 'wireTransferDataValidation': {
      const { wireTransferDataValidation } = contractPage;

      return {
        ...state,
        contractPage: {
          ...contractPage,
          wireTransferDataValidation: {
            ...wireTransferDataValidation,
            ...action.payload,
          },
        },
      };
    }

    case 'resetWireTransfer': {
      return {
        ...state,
        contractPage: {
          ...contractPage,
          wireTransferFormData: contractPageDefaultState.wireTransferFormData,
        },
      };
    }

    case 'setWireTransferOptions': {
      const { wireTransferOptions } = contractPage;

      return {
        ...state,
        contractPage: {
          ...contractPage,
          wireTransferOptions: {
            ...wireTransferOptions,
            ...action.payload,
          },
        },
      };
    }

    case 'resetWireTransferOptions': {
      return {
        ...state,
        contractPage: {
          ...contractPage,
          wireTransferOptions: contractPageDefaultState.wireTransferOptions,
        },
      };
    }

    case 'activeProSubsAccountIds': {
      return {
        ...state,
        contractPage: {
          ...contractPage,
          activeProSubsAccountIds: action.payload,
        },
      };
    }

    case 'setNewWireTransferId': {
      return {
        ...state,
        contractPage: {
          ...contractPage,
          newWireTransferId: action.payload,
        },
      };
    }

    case 'showViewUsageReportModal': {
      return {
        ...state,
        contractPage: {
          ...contractPage,
          showViewUsageReportModal: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
