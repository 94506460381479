import * as I from '../pages/couponsPage/CouponsPage.types';

export const COUPONS_TABLE_DEFAULT_SORT = [{ id: 'postedDate', desc: true }];

export const couponsFilterSectionLabels = {
  couponCode: 'Coupon code',
  couponAmount: 'Coupon amount',
  expiryDate: 'Expiry date',
  status: 'Status',
};

export const couponPopUpLabels = {
  newCoupon: 'Create new coupon',
  extendCoupon: 'Extend coupon',
  couponCode: 'Coupon code',
  couponAmount: 'Coupon amount',
  description: 'Description',
  expiryDate: 'Expiry date',
  maxUses: 'Max uses',
  optional: 'Optional',
  unlimited: 'Unlimited',
  creditExpirationPeriod: 'Credit expiration',
  inMonths: 'in months',
  addable: 'Addable',
  addableTooltip: 'Can be added to existing coupons',
  customerRetention: 'Customer retention',
};

export const couponsPageFilterItemsDefaultValues: I.CouponsPageFilterItems = {
  couponCode: '',
  minAmount: '',
  expiryDateTo: null,
  status: {
    code: '',
    label: 'All',
  },
};

const couponsTableFilterItemsDefaultValues: I.CouponsTableFilterItems = {
  sortBy: 'postedDate',
  sortDirection: 'desc',
  pageSize: 10,
  offset: 0,
  pageIndex: 0,
  pageCount: 0,
};

export const couponsFilterItemsDefaultValues: I.CouponsFilterItems = {
  ...couponsPageFilterItemsDefaultValues,
  ...couponsTableFilterItemsDefaultValues,
};

export const couponPopupDataDefaultValues: I.CouponPopupDataDefaultValuesInterface = {
  couponCode: '',
  expiryDate: null,
  newExpiryDate: null,
  amount: '',
  description: '',
  maxUses: null,
  couponId: undefined,
  creditExpirationPeriod: undefined,
  addable: false,
  forCustomerRetention: false,
};
