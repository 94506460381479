import moment from 'moment';
import {
  AuditLogsFilterInterface,
  AuditLogsPageFilterInterface,
  AuditLogsTableFilterInterface,
} from '../interfaces/auditLogsStore.type';

const defaultAuditLogsPageFilterItems: AuditLogsPageFilterInterface = {
  startDateFrom: moment.utc().subtract(1, 'day').startOf('day'),
  startDateTo: moment.utc().startOf('day'),
  queryText: '',
};

const defaultAuditLogsTableFilterItems: AuditLogsTableFilterInterface = {
  sortDirection: 'desc',
  pageSize: 10,
  offset: 0,
  pageIndex: 0,
  pageCount: 0,
  sortBy: '',
};

const defaultAuditLogsFilterItems: AuditLogsFilterInterface = {
  ...defaultAuditLogsPageFilterItems,
  ...defaultAuditLogsTableFilterItems,
};

export const auditLogsPageReducerDefaultState = {
  auditLogsTableData: [],
  loadingAuditLogsTableData: false,
  auditLogsFilterItems: defaultAuditLogsFilterItems,
};

export default function auditLogsPageReducer(state, action) {
  const { auditLogsPage } = state;

  switch (action.type) {
    case 'setLoadingAuditLogsTableData':
      return {
        ...state,
        auditLogsPage: {
          ...auditLogsPage,
          loadingAuditLogsTableData: action.payload,
        },
      };

    case 'setAuditLogsFilterItems': {
      const { auditLogsFilterItems } = auditLogsPage;

      return {
        ...state,
        auditLogsPage: {
          ...auditLogsPage,
          auditLogsFilterItems: {
            ...auditLogsFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'resetAuditLogsPageFilterItems': {
      const { auditLogsFilterItems } = auditLogsPage;

      return {
        ...state,
        auditLogsPage: {
          ...auditLogsPage,
          auditLogsFilterItems: {
            ...auditLogsFilterItems,
            ...defaultAuditLogsPageFilterItems,
          },
        },
      };
    }

    case 'setAuditLogsTableData': {
      return {
        ...state,
        auditLogsPage: {
          ...auditLogsPage,
          auditLogsTableData: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
