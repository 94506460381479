import moment from 'moment';
import * as R from 'ramda';

export function prepareFilterTableRequestData(reqParams, omitKeys = ['']): object {
  const removeEmptyParams = R.compose(
    R.omit(omitKeys),
    R.reject(R.equals('')),
    R.reject(R.equals(null))
  )(reqParams);

  return {
    ...removeEmptyParams,
    ...(removeEmptyParams.startDateFrom && {
      startDateFrom: moment.utc(removeEmptyParams.startDateFrom).startOf('day').valueOf(),
    }),
    ...(removeEmptyParams.startDateTo && {
      startDateTo: moment.utc(removeEmptyParams.startDateTo).endOf('day').valueOf(),
    }),
    ...(removeEmptyParams.endDateFrom && {
      endDateFrom: moment.utc(removeEmptyParams.endDateFrom).startOf('day').valueOf(),
    }),
    ...(removeEmptyParams.endDateTo && {
      endDateTo: moment.utc(removeEmptyParams.endDateTo).endOf('day').valueOf(),
    }),
    ...(removeEmptyParams.expiryDateTo && {
      expiryDateTo: moment.utc(removeEmptyParams.expiryDateTo).endOf('day').valueOf(),
    }),
    ...(removeEmptyParams.expiryDate && {
      expiryDate: moment.utc(removeEmptyParams.expiryDate).endOf('day').valueOf(),
    }),
  };
}
