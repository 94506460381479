import * as R from 'ramda';
import { displayErrors } from '../rootPage/RootPage.utils';
import { api, buildUrl } from '../../api/config';
import { newBdbId } from './bdbsModal/bdbsModal.utils';

export async function getTableData(reqParams, dispatch) {
  try {
    dispatch({ type: 'setLoadingManualAaTableData', payload: true });

    const removeEmptyParams = R.reject(R.equals(''), reqParams);

    const response = await api.get(`${buildUrl('subscriptions')}/override-marketplace-reporting`, {
      params: removeEmptyParams,
    });

    if (response.status === 200) {
      const newData = response.data;
      dispatch({ type: 'setManualAaTableData', payload: newData.items });
      dispatch({ type: 'setLoadingManualAaTableData', payload: false });
      return newData.data;
    }
  } catch (err) {
    displayErrors(err, "Failed trying to get subscription for manual AA Page table data'");
  }

  return null;
}

export async function getBdbsTableData(subscriptionId, dispatch) {
  try {
    dispatch({ type: 'loadingBdbsTableData', payload: true });

    const response = await api.get(`${buildUrl('bdbs')}/aa-manual/subscription/${subscriptionId}`);

    if (response.status === 200) {
      const newData = response.data;
      dispatch({ type: 'setBdbsTableData', payload: newData.items });
      dispatch({ type: 'loadingBdbsTableData', payload: false });
      return newData.data;
    }
  } catch (err) {
    displayErrors(err, 'Failed trying to get bdbs for manual AA Page table data');
  }

  return null;
}

export async function addBdb(props, dispatch) {
  try {
    const { reqParams, newState, callBack } = props;

    const response = await api.post(`${buildUrl('bdbs')}/aa-manual`, reqParams);

    if (response.status === 200) {
      const stateWithNewBdbId = newState.map((bdb) => {
        if (bdb.bdbId === newBdbId) {
          return { ...bdb, bdbId: response.data.bdbId };
        }
        return bdb;
      });
      dispatch({ type: 'setBdbsTableData', payload: stateWithNewBdbId });
      callBack();
    }
  } catch (err) {
    displayErrors(err, 'There was a problem while trying to update the bdb');
  }

  return null;
}

export async function updateBdb(props, dispatch) {
  try {
    const { reqParams, newState, callBack } = props;

    const response = await api.put(`${buildUrl('bdbs')}/aa-manual/${reqParams.bdbId}`, reqParams);

    if (response.status === 200) {
      dispatch({ type: 'setBdbsTableData', payload: newState });
      callBack();
    }
  } catch (err) {
    displayErrors(err, 'There was a problem while trying to update the bdb');
  }

  return null;
}

export async function deleteBdb(props, dispatch) {
  try {
    const { reqParams, newState } = props;

    const response = await api.delete(`${buildUrl('bdbs')}/aa-manual/${reqParams.bdbId}`);

    if (response.status === 200) {
      dispatch({ type: 'setBdbsTableData', payload: newState });
    }
  } catch (err) {
    displayErrors(err, 'There was a problem while trying to delete the bdb');
  }

  return null;
}
