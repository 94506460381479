import React from 'react';
import * as R from 'ramda';

import * as CS from '../../styles/common.style';

interface TableStatusProps {
  status: string;
  statuses: { code: string; label: string }[];
  customStatusWidth?: string;
  dataTestId?: string;
  useFullWidth?: boolean;
  hideLabel?: boolean;
}

const TableStatus = (props: TableStatusProps) => {
  const { status, statuses, customStatusWidth, useFullWidth, hideLabel, dataTestId } = props;

  const buildStatus = () => {
    const result = R.find(R.propEq('code', status), statuses);

    return result?.label;
  };

  if (!status) {
    return null;
  }

  return (
      <CS.Status status={status} useFullWidth={useFullWidth}>
          <span />
          <CS.StatusValue data-testid={dataTestId} customStatusWidth={customStatusWidth}>
              {(!hideLabel && buildStatus()) || ''}
          </CS.StatusValue>
      </CS.Status>
  );
};

export default TableStatus;
