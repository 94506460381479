import React from 'react';
import { TableRowButtons } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import * as S from './Cells.style';

const ActionsCell = ({ row: { original }, getActionProps, idKey }) => {
  const {
    editMode,
    tableHasRowInEditMode,
    hasActionsPermissions,
    actions: { onEdit, onCancel, onSave, onDelete },
  } = getActionProps(original[idKey]);

  const areButtonsHidden = !hasActionsPermissions || (tableHasRowInEditMode && !editMode);

  return (
      <S.Cell>
          <TableRowButtons
              handleSubmitButtonClick={onSave}
              handleCancelButtonClick={onCancel}
              handleEditButtonClick={onEdit}
              handleDeleteButtonClick={onDelete}
              editMode={editMode}
              isSubmitOrEditHidden={areButtonsHidden}
              isCancelOrDeleteHidden={areButtonsHidden}
      />
      </S.Cell>
  );
};

export default ActionsCell;
