// @ts-nocheck

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastPlusIcon } from '@redislabsdev/redis-ui-icons';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { HoverableTable } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import { addBdb, deleteBdb, updateBdb } from '../ManualAa.api';
import * as S from '../ManualAa.style';
import useBdbsTableColumns from '../hooks/useBdbsTableColumns';
import useBdbs from '../hooks/useBdbs';
import useBdbErrorsHandler from '../hooks/useBdbErrorsHandler';
import { MANUAL_A_A_WRITE } from '../../../constants/permissionsConstants';
import { initialBdb, newBdbId } from './bdbsModal.utils';

const BdbsModal: React.FC = () => {
  const showBdbsModal = useSelector((state) => state.manualAaPage.bdbsModalValues.showModal);
  const bdbsTableData = useSelector((state) => state.manualAaPage.bdbsModalValues.bdbsTableData);
  const loadingBdbsTableData = useSelector(
    (state) => state.manualAaPage.bdbsModalValues.loadingBdbsTableData
  );
  const subscriptionId = useSelector((state) => state.manualAaPage.bdbsModalValues.subscriptionId);
  const permissions = useSelector((state) => state.rootPage.permissions);

  const [idInEditMode, setIdInEditMode] = React.useState<number | null | string>(null);
  const [tempRowData, setTempRowData] = React.useState(initialBdb);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState({ show: false, bdbId: null });

  const dispatch = useDispatch();
  const columns = useBdbsTableColumns();
  const { data, setData } = useBdbs(bdbsTableData, idInEditMode);
  const { inputErrorsHandler, setInputErrorsHandler } = useBdbErrorsHandler();

  const title = `Subscription #${subscriptionId}`;
  const tableHasRowInEditMode = idInEditMode !== null;
  const canWriteManualAa = permissions.includes(MANUAL_A_A_WRITE);

  const handleInputChange = (
    key: number | string,
    value: string | number | boolean,
    errors: string
  ): void => {
    setInputErrorsHandler((oldState) => ({
      ...oldState,
      [key]: {
        ...oldState[key],
        error: errors,
      },
    }));
    setTempRowData((oldState) => ({ ...oldState, [key]: value }));
  };

  const handleAddRowClick = () => {
    setTempRowData(initialBdb);
    setIdInEditMode(initialBdb.bdbId);
    setData((oldData) => [...oldData, tempRowData]);
  };

  const saveBdb = (bdbId) => {
    if (bdbId === newBdbId) {
      return addBdb(
        {
          reqParams: getPayload(bdbId),
          newState: getNewState('add'),
          callBack: (isSuccess = true) => {
            isSuccess && clearState(bdbId);
          },
        },
        dispatch
      );
    }

    if (rowChanged()) {
      return updateBdb(
        {
          reqParams: getPayload(bdbId),
          newState: getNewState('update'),
          callBack: (isSuccess = true) => {
            isSuccess && clearState(bdbId);
          },
        },
        dispatch
      );
    }

    return clearState(bdbId);
  };

  const handleSaveButtonClick = async (bdbId) => {
    let isValid = true;
    Object.keys(inputErrorsHandler).forEach((key, index) => {
      try {
        inputErrorsHandler[key].validator.validateSync(tempRowData[key]);
        if (isValid && index === Object.keys(inputErrorsHandler).length - 1) {
          saveBdb(bdbId);
        }
      } catch (e) {
        setInputErrorsHandler((oldState) => ({
          ...oldState,
          [key]: {
            ...oldState[key],
            error: e.message,
          },
        }));
        isValid = false;
      }
    });
  };

  const handleCancelButtonClick = (bdbId) => {
    clearState(bdbId);
  };

  const handleEditButtonClick = (bdbId) => {
    setIdInEditMode(bdbId);
    const bdbToEdit = data.find((item) => item.bdbId === bdbId);
    bdbToEdit &&
      setTempRowData({
        bdbId,
        bdbName: bdbToEdit.bdbName,
        sizeGb: bdbToEdit.sizeGb,
        maxThroughput: bdbToEdit.maxThroughput,
        replication: bdbToEdit.replication,
      });
  };

  const handleDelete = () => {
    deleteBdb(
      {
        reqParams: { bdbId: deleteConfirmation.bdbId },
        newState: getNewState('delete'),
      },
      dispatch
    );

    handleDeleteConfirmationModalCancel();
  };

  const handleBdbsModalCancel = () => {
    if (idInEditMode) {
      clearState(idInEditMode);
    }
    setData([]);
    dispatch({ type: 'resetBdbsModalValues' });
  };

  const handleDeleteConfirmationModalCancel = () => {
    setDeleteConfirmation({ show: false, bdbId: null });
  };

  const rowChanged = () => {
    if (idInEditMode !== newBdbId) {
      const bdb = data.find((item) => item.bdbId === idInEditMode);
      return bdb && Object.keys(tempRowData).some((key) => tempRowData[key] !== bdb[key]);
    }
    return true;
  };

  const getPayload = (bdbId) => ({
    bdbId: bdbId === newBdbId ? null : bdbId,
    name: tempRowData.bdbName,
    sizeGb: tempRowData.sizeGb,
    maxThroughput: tempRowData.maxThroughput,
    replication: tempRowData.replication,
    subscriptionId,
  });

  const getNewState = (action) => {
    switch (action) {
      case 'add':
        return [...bdbsTableData, tempRowData];

      case 'update':
        return bdbsTableData.map((bdb) => {
          if (bdb.bdbId === idInEditMode) {
            return tempRowData;
          }
          return bdb;
        });

      case 'delete':
        return bdbsTableData.filter((bdb) => bdb.bdbId !== deleteConfirmation.bdbId);

      default:
        return bdbsTableData;
    }
  };

  const clearState = (bdbId) => {
    Object.keys(inputErrorsHandler).forEach((key) => {
      setInputErrorsHandler((oldState) => ({
        ...oldState,
        [key]: {
          ...oldState[key],
          error: '',
        },
      }));
    });
    if (bdbId === newBdbId) {
      setData((old) => old.filter((row) => row.bdbId !== bdbId));
    }
    setIdInEditMode(null);
    setTempRowData(initialBdb);
  };

  const getActionProps = (bdbId) => ({
    editMode: bdbId < 0 || idInEditMode === bdbId,
    tableHasRowInEditMode,
    hasActionsPermissions: canWriteManualAa,
    actions: {
      onEdit: () => handleEditButtonClick(bdbId),
      onCancel: () => handleCancelButtonClick(bdbId),
      onSave: () => handleSaveButtonClick(bdbId),
      onDelete: () => setDeleteConfirmation({ show: true, bdbId }),
    },
  });

  const renderDeleteConfirmationModal = () => {
    const selectedBdb = data?.find((bdb) => bdb.bdbId === deleteConfirmation.bdbId);
    return (
      <ConfirmationDialog
        isOpen={deleteConfirmation.show}
        handleModalCancel={handleDeleteConfirmationModalCancel}
        title="Delete database"
        cancelButtonLabel="Cancel"
        submitButtonLabel="Delete"
        handleModalSubmit={handleDelete}
      >
        {`Are you sure you want to delete database "${selectedBdb?.bdbName}"?`}
      </ConfirmationDialog>
    );
  };

  return (
    <>
      <ConfirmationDialog
        title={title}
        isOpen={showBdbsModal}
        cancelButtonLabel="Close"
        handleModalCancel={handleBdbsModalCancel}
      >
        <S.PlusButton
          data-testid="button--add-manual-aa-bdb"
          onClick={handleAddRowClick}
          disabled={!canWriteManualAa || tableHasRowInEditMode}
        >
          <ToastPlusIcon size="L" color="white" />
        </S.PlusButton>
        <S.BdbsTableWrapper>
          {loadingBdbsTableData ? (
            <div>Loading...</div>
          ) : (
            <HoverableTable
              columns={columns}
              data={data}
              getActionProps={getActionProps}
              tempRowData={tempRowData}
              inputErrorsHandler={inputErrorsHandler}
              handleInputChange={handleInputChange}
              idKey="bdbId"
            />
          )}
        </S.BdbsTableWrapper>
      </ConfirmationDialog>
      {deleteConfirmation.show && renderDeleteConfirmationModal()}
    </>
  );
};

export default BdbsModal;
