import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';

import { extendPoc, getPocsTableData } from '../PocsPage.api';
import { calculateExtendPocPeriod } from '../PocsPage.utils';
import * as CS from '../../../styles/common.style';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import * as S from './ExtendPocPopup.style';

const ExtendPocPopup: React.FC = () => {
  const dispatch = useDispatch();

  const pocsFilterItems = useSelector((state: StoreInterface) => state.pocsPage.pocsFilterItems);
  const showExtendPocModal = useSelector(
    (state: StoreInterface) => state.pocsPage.showExtendPocModal
  );
  const extendPocModalData = useSelector(
    (state: StoreInterface) => state.pocsPage.extendPocModalData
  );

  const { pocId, endDate, selectedEndDate } = extendPocModalData;
  const initialEndDatePlusOne = moment.utc(endDate).add(1, 'days').startOf('day');

  return (
      <ConfirmationDialog
          title="Extend POC"
          isOpen={showExtendPocModal}
          cancelButtonLabel="Cancel"
          submitButtonLabel="Save"
          handleModalCancel={() => {
        dispatch({ type: 'setExtendPocModalData', payload: {} });
        dispatch({ type: 'setShowExtendPocModal', payload: false });
      }}
          handleModalSubmit={() => {
        extendPoc(
          {
            pocId,
            daysNo: moment
              .utc(selectedEndDate || initialEndDatePlusOne)
              .endOf('day')
              .diff(moment.utc(endDate).endOf('day'), 'days'),
          },
          () => {
            getPocsTableData(pocsFilterItems, dispatch);
            dispatch({ type: 'setExtendPocModalData', payload: {} });
            dispatch({ type: 'setShowExtendPocModal', payload: false });
          }
        );
      }}
    >
          <S.ExtendPopupContainer>
              <S.UpdateEndDateWrapper>
                  <span>
                      Update end date:
                  </span>
                  <S.EndDateSpan>
                      {(selectedEndDate || initialEndDatePlusOne).format('DD/M/YY')}
                  </S.EndDateSpan>
              </S.UpdateEndDateWrapper>
              <CS.DatePickerWrapper
                  useAsRange
                  currentMonth={initialEndDatePlusOne.toDate()}
                  disabledDays={{
            before: initialEndDatePlusOne.startOf('day').toDate(),
            after: calculateExtendPocPeriod(endDate)?.toDate(),
          }}
                  currentDateAsObject={{
            from: initialEndDatePlusOne.startOf('day').toDate(),
            to: (selectedEndDate || initialEndDatePlusOne).toDate(),
          }}
                  setCurrentDay={(newDate) => {
            dispatch({
              type: 'setExtendPocModalData',
              payload: {
                ...extendPocModalData,
                selectedEndDate: moment.utc(newDate.to).startOf('day'),
              },
            });
          }}
        />
          </S.ExtendPopupContainer>
      </ConfirmationDialog>
  );
};

export default ExtendPocPopup;
