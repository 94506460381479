import {
  ExtendPocModalDataDefaultValuesInterface,
  NewPocPopupDataDefaultValuesInterface,
  PocsFilterItemsDefaultValuesInterface,
} from '../interfaces/pocsPageStore.type';

export const pocsFilterItemsDefaultValues: PocsFilterItemsDefaultValuesInterface = {
  pocId: '',
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  smAccountId: '',
  smAccountName: '',
  boUserName: '',
  status: {
    code: '',
    label: 'All',
  },
  pageSize: 1000,
};

export const newPocPopupDataDefaultValues: NewPocPopupDataDefaultValuesInterface = {
  accountNumber: '',
  pocStartDate: null,
  pocEndDate: null,
  comments: '',
  newPocEmailList: [],
};

const extendPocModalDataDefaultValues: ExtendPocModalDataDefaultValuesInterface = {
  pocId: '',
  endDate: null,
  selectedEndDate: null,
};

export const pocsPageDefaultState = {
  // TODO: add interfaces
  pocsFilterItems: pocsFilterItemsDefaultValues,
  pocsTableData: [],
  loadingPocsTableData: false,
  showCreateNewPocModal: false,
  showExtendPocModal: false,
  newPocPopupData: newPocPopupDataDefaultValues,
  newPocValidation: {
    isNewPocEmailListValid: true,
  },
  extendPocModalData: extendPocModalDataDefaultValues,
  stopPocModalData: {}, // TODO: check interfaces and add them
  pocStatuses: [{ code: '', label: 'All' }],
  loadPocConfig: true,
};

export default function contractPageReducer(state, action) {
  const { pocsPage } = state;

  switch (action.type) {
    case 'setLoadingPocsTableData':
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          loadingPocsTableData: action.payload,
        },
      };

    case 'setPocsFilterItems': {
      const { pocsFilterItems } = pocsPage;

      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          pocsFilterItems: {
            ...pocsFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'setPocsTableData': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          pocsTableData: action.payload,
        },
      };
    }

    case 'clearIndividualDate': {
      const { pocsFilterItems } = pocsPage;

      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          pocsFilterItems: {
            ...pocsFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'resetPocsFilterItems': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          pocsFilterItems: pocsFilterItemsDefaultValues,
        },
      };
    }

    case 'setShowExtendPocModal': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          showExtendPocModal: action.payload,
        },
      };
    }

    case 'setShowCreateNewPocModal': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          showCreateNewPocModal: action.payload,
        },
      };
    }

    case 'setExtendPocModalData': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          extendPocModalData: action.payload,
        },
      };
    }

    case 'setStopPocModalData': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          stopPocModalData: action.payload,
        },
      };
    }

    case 'setPocStatuses': {
      const { pocStatuses } = pocsPage;

      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          pocStatuses: [...pocStatuses, ...action.payload],
        },
      };
    }

    case 'setLoadPocConfig': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          loadPocConfig: action.payload,
        },
      };
    }

    case 'setNewPocPopupData': {
      const { newPocPopupData } = pocsPage;

      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          newPocPopupData: {
            ...newPocPopupData,
            ...action.payload,
          },
        },
      };
    }

    case 'setNewPocValidation': {
      const { newPocValidation } = pocsPage;

      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          newPocValidation: {
            ...newPocValidation,
            ...action.payload,
          },
        },
      };
    }

    case 'resetNewPocValidation': {
      return {
        ...state,
        pocsPage: {
          ...pocsPage,
          newPocPopupData: newPocPopupDataDefaultValues,
          newPocValidation: pocsPageDefaultState.newPocValidation,
        },
      };
    }

    default:
      return state;
  }
}
