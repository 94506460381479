import moment from 'moment';
import * as R from 'ramda';
import { api, buildUrl } from '../../api/config';
import { displayErrors } from '../rootPage/RootPage.utils';
import { prepareFilterTableRequestData } from '../../utils/filterTable.utils';
import { showToast } from '../../components/Toast/Toast';

const getPocsTableDataApiRequest = (dataToSend) =>
  api.get(`${buildUrl('poc')}`, {
    params: dataToSend,
  });

export async function getPocsTableData(reqParams, dispatch) {
  dispatch({ type: 'setLoadingPocsTableData', payload: true });

  const newParams = {
    ...reqParams,
    status: reqParams.status.code,
  };

  getPocsTableDataApiRequest(prepareFilterTableRequestData(newParams))
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({
          type: 'setPocsTableData',
          payload: data?.items,
        });

        dispatch({ type: 'setLoadingPocsTableData', payload: false });
      }
    })
    .catch((err) => {
      displayErrors(err, "Failed trying to get POCs info's");
    });
}

const createNewPocRequest = (dataToSend) => api.post(`${buildUrl('poc')}`, dataToSend);

export async function createNewPoc(reqParams, onSuccessCallback) {
  const dataToSend = () => {
    const { accountNumber, newPocEmailList, pocEndDate, utcDateStartOfDay, comments } = reqParams;

    return prepareFilterTableRequestData({
      accountId: accountNumber,
      additionalEmails: R.empty(newPocEmailList)
        ? null
        : R.map(R.trim, R.split(',', newPocEmailList)),
      endDate: moment.utc(pocEndDate).endOf('day').valueOf(),
      startDate: moment.utc(utcDateStartOfDay).startOf('day').valueOf(),
      comment: comments,
    });
  };

  createNewPocRequest(dataToSend())
    .then(({ status }) => {
      if (status === 200) {
        showToast('The POC was created successfully', 'success');

        onSuccessCallback();
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to create a new POC');
    });
}

const extendPocRequest = (pocId: number, daysNo: number) =>
  api.put(`${buildUrl('poc')}/${pocId}/extend/${daysNo}`);

export async function extendPoc(reqParams, onSuccessCallback) {
  const { pocId, daysNo } = reqParams;

  extendPocRequest(pocId, daysNo)
    .then(({ status }) => {
      if (status === 200) {
        showToast(`The POC ${pocId} was extended successfully`, 'success');
        onSuccessCallback();
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to extend POC');
    });
}

const stopPocRequest = (pocId: number) => api.put(`${buildUrl('poc')}/${pocId}/stop`);

export async function stopPoc(pocId, callback) {
  stopPocRequest(pocId)
    .then(({ status }) => {
      if (status === 200) {
        showToast(`The POC ${pocId} was stopped successfully`, 'success');
        callback();
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed trying to stop POC ${pocId}`);
    });
}

const pocAccountNameRequest = (accId: number) => api.get(`${buildUrl('accounts')}/${accId}/name`);

export async function getPocAccountName(accId: number, callback) {
  pocAccountNameRequest(accId)
    .then(({ status, data }) => {
      if (status === 200) {
        callback(data.name);
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed trying to get the name of ${accId} account`);
    });
}

const pocStatusesRequest = () => api.get(`${buildUrl('bousers')}/configuration/poc`);

export async function getPocStatuses(dispatch) {
  pocStatusesRequest()
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({ type: 'setPocStatuses', payload: data.pocStatuses });
        dispatch({ type: 'setLoadPocConfig', payload: false });
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to get POC statuses');
    });
  return null;
}

export const exportPocTable = async (pocsFilterItems) => {
  const newParams = {
    ...pocsFilterItems,
    status: pocsFilterItems.status.code,
  };

  const { data } = await api.get<Blob>(`${buildUrl('poc')}/export`, {
    headers: {
      'Content-Type': 'text/csv; charset=UTF-8',
      'Content-Disposition': 'attachment;filename=pocs.csv',
    },
    responseType: 'blob',
    params: prepareFilterTableRequestData(newParams),
  });

  const blob = new Blob([data], { type: 'text/csv' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = 'pocs.csv';
  link.click();
};
