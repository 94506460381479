import React, { useEffect, useState } from 'react';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { Input } from '@redislabsdev/redis-ui-components';
import { CalendarIcon, CancelIcon } from '@redislabsdev/redis-ui-icons';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import * as CS from '../../../styles/common.style';

import { updateStateOnInputType, utcDateStartOfDay } from '../../../utils/common.utils';
import { createNewCoupon, extendCoupon, getCouponsTableData } from '../CouponsPage.api';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import { errorsMessages } from '../../../utils/errorMessages';
import { couponPopUpLabels } from '../../../constants/couponsPageConstants';
import * as S from './CouponPopup.style';
import { CouponPhase2 } from './CouponPhase2';

const CouponPopup: React.FC = () => {
  const dispatch = useDispatch();

  const couponPopupData = useSelector((state: StoreInterface) => state.couponsPage.couponPopupData);
  const couponsFilterItems = useSelector(
    (state: StoreInterface) => state.couponsPage.couponsFilterItems
  );
  const showCouponModal = useSelector((state: StoreInterface) => state.couponsPage.showCouponModal);
  const isCouponCodeValid = useSelector(
    (state: StoreInterface) => state.couponsPage.newCouponValidation.isCouponCodeValid
  );
  const isCouponAmountValid = useSelector(
    (state: StoreInterface) => state.couponsPage.newCouponValidation.isCouponAmountValid
  );
  const isCouponMaxUsesValid = useSelector(
    (state: StoreInterface) => state.couponsPage.newCouponValidation.isCouponMaxUsesValid
  );
  const isCreditExpirationValid = useSelector(
    (state: StoreInterface) => state.couponsPage.newCouponValidation.isCreditExpirationValid
  );
  const isCouponCreditsExpirationPeriodMandatory = useSelector(
    (state: StoreInterface) => state.couponsPage.couponCreditsExpirationPeriodMandatory
  );

  const {
    couponId,
    couponCode,
    amount,
    expiryDate,
    newExpiryDate,
    description,
    maxUses,
    creditExpirationPeriod,
    addable,
    forCustomerRetention,
  } = couponPopupData;

  const [showExpiryDatePicker, setShowExpiryDatePicker] = useState(false);
  const [currentEndMonth, setCurrentEndMonth] = useState<Moment>(utcDateStartOfDay);

  useEffect(() => {
    newExpiryDate && setCurrentEndMonth(moment.utc(newExpiryDate).startOf('day'));
  }, [newExpiryDate]); // eslint-disable-line

  const successCallback = () => {
    dispatch({ type: 'setShowCouponModal', payload: false });
    dispatch({ type: 'resetNewCouponValidation' });
    setCurrentEndMonth(moment(utcDateStartOfDay));
    getCouponsTableData(couponsFilterItems, dispatch);
  };

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setCouponPopupData',
      dispatch,
    });

  const onSubmit = () => {
    if (couponId) {
      extendCoupon(
        couponId,
        {
          maxUses,
          expiryDate: newExpiryDate,
        },
        successCallback
      );
    } else {
      createNewCoupon(
        {
          couponCode,
          description,
          maxUses,
          amount,
          expiryDate,
          addable,
          forCustomerRetention,
          creditExpirationPeriod: isCouponCreditsExpirationPeriodMandatory
            ? creditExpirationPeriod || 1
            : undefined,
        },
        successCallback
      );
    }
  };

  const getCurrentDay = () => {
    const newExpDate = moment.utc(expiryDate).startOf('day');
    return couponId
      ? moment.max([moment(utcDateStartOfDay).add(1, 'd'), moment(newExpDate).add(1, 'd')]).toDate()
      : moment(utcDateStartOfDay).add(1, 'd').toDate();
  };

  return (
    <ConfirmationDialog
      title={couponId ? couponPopUpLabels.extendCoupon : couponPopUpLabels.newCoupon}
      isOpen={showCouponModal}
      cancelButtonLabel="Cancel"
      submitButtonLabel="Save"
      handleModalCancel={() => {
        dispatch({ type: 'setShowCouponModal', payload: false });
        dispatch({ type: 'resetNewCouponValidation' });
        setCurrentEndMonth(moment(utcDateStartOfDay));
      }}
      handleModalSubmit={onSubmit}
      submitDisabled={
        !isCouponCodeValid ||
        !isCouponAmountValid ||
        !couponCode ||
        !expiryDate ||
        !amount ||
        !isCouponMaxUsesValid ||
        !isCreditExpirationValid
      }
    >
      <S.CouponPopupContainer>
        <S.CouponPopupRow>
          <label htmlFor="accountNumber">
            {couponPopUpLabels.couponCode}
            {!couponId ? ' *' : ''}
            {couponId ? (
              <S.ReadOnlyLabel>
                {couponCode}
              </S.ReadOnlyLabel>
            ) : (
              <Input
                autoComplete="new-coupon-code"
                data-testid="textinput--coupon-code"
                width="100%"
                maxLength={20}
                type="text"
                name="couponCode"
                id="couponCode"
                error={(!isCouponCodeValid && errorsMessages.couponCode) as string}
                value={couponCode}
                onBlur={({ target }) => {
                  dispatch({
                    type: 'setNewCouponValidation',
                    payload: { isCouponCodeValid: !target.value.includes(' ') },
                  });
                }}
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            )}
          </label>

          <label htmlFor="amount">
            {couponPopUpLabels.couponAmount}
            {!couponId ? ' *' : ''}
            {couponId ? (
              <S.ReadOnlyLabel>
                {`$ ${amount || 0}`}
              </S.ReadOnlyLabel>
            ) : (
              <Input
                autoComplete="new-coupon-amount"
                min={0.001}
                max={999999999.999}
                step={0.001}
                data-testid="textinput--coupon-amount"
                width="100%"
                placeholder="$"
                type="number"
                name="amount"
                id="amount"
                error={(!isCouponAmountValid && errorsMessages.couponAmount) as string}
                value={amount}
                onBlur={({ target }) => {
                  dispatch({
                    type: 'setNewCouponValidation',
                    payload: { isCouponAmountValid: target.validity.valid },
                  });
                }}
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            )}
          </label>
        </S.CouponPopupRow>
        <S.CouponPopupRow>
          <label htmlFor="description">
            {couponPopUpLabels.description}
            {couponId ? (
              <S.ReadOnlyLabel>
                {description}
              </S.ReadOnlyLabel>
            ) : (
              <CS.Textarea
                name="description"
                id="description"
                maxLength={200}
                data-testid="textarea--description"
                rows={3}
                value={description} // protection for null values
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            )}
          </label>
        </S.CouponPopupRow>
        <S.CouponPopupRow>
          <label htmlFor="expiryDate">
            {couponPopUpLabels.expiryDate}
            {!couponId ? ' *' : ''}
            <CS.SpanWithDate>
              <span>
                {couponId
                  ? newExpiryDate?.format('DD-MMM-YYYY')
                  : expiryDate?.format('DD-MMM-YYYY')}
              </span>
              <CS.SpanWithDateButtonsWrapper>
                {expiryDate && !couponId ? (
                  <Button
                    data-testid="button--clear-coupon-expiry-date"
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      dispatch({
                        type: 'setCouponPopupData',
                        payload: { expiryDate: null },
                      });
                    }}
                  >
                    <CancelIcon size="L" />
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    size="small"
                    data-testid="button--coupon-expiry-date"
                    onClick={() => {
                      setShowExpiryDatePicker(!showExpiryDatePicker);
                    }}
                  >
                    <CalendarIcon size="L" />
                  </Button>
                )}
              </CS.SpanWithDateButtonsWrapper>
            </CS.SpanWithDate>
          </label>
          <label htmlFor="maxUses">
            {couponPopUpLabels.maxUses}
            {' '}
            <S.OptionalSpan>
              (
              {couponPopUpLabels.optional}
              )
            </S.OptionalSpan>
            <Input
              autoComplete="new-coupon-max-uses"
              data-testid="textinput--coupon-max-uses"
              width="100%"
              placeholder={couponPopUpLabels.unlimited}
              type="number"
              min={1}
              max={2147483647}
              name="maxUses"
              id="maxUses"
              error={(!isCouponMaxUsesValid && errorsMessages.couponMaxUses) as string}
              value={maxUses || ''}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10);
                dispatch({
                  type: 'setCouponPopupData',
                  payload: {
                    maxUses: value || null,
                  },
                });
              }}
              onBlur={({ target }) => {
                dispatch({
                  type: 'setNewCouponValidation',
                  payload: { isCouponMaxUsesValid: target.validity.valid },
                });
              }}
            />
          </label>
        </S.CouponPopupRow>
        <CouponPhase2 onStateChange={updateStateOnInputTypeWrapper} />
      </S.CouponPopupContainer>
      {showExpiryDatePicker ? (
        <S.DatePickerAligner>
          <CS.DatePickerWrapper
            disabledDays={{
              before: getCurrentDay(),
            }}
            showOnClickOutsideFn={() => {
              setShowExpiryDatePicker(false);
            }}
            currentMonth={currentEndMonth.toDate()}
            setCurrentMonth={(newMonth, keepPickerOpen) => {
              setCurrentEndMonth(moment(newMonth));
              setShowExpiryDatePicker(keepPickerOpen);
            }}
            currentDay={newExpiryDate?.toDate() || expiryDate?.toDate() || getCurrentDay()}
            setCurrentDay={(newDate) => {
              dispatch({
                type: 'setCouponPopupData',
                payload: couponId
                  ? {
                    newExpiryDate: moment(newDate as Date),
                  }
                  : {
                    expiryDate: moment(newDate as Date),
                  },
              });
              setShowExpiryDatePicker(false);
            }}
          />
        </S.DatePickerAligner>
      ) : null}
    </ConfirmationDialog>
  );
};

export default CouponPopup;
