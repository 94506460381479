export const columnNames = [
  {
    header: 'Subscription ID',
    accessor: 'subscriptionId',
    disableFilters: true,
  },
  {
    header: 'Subscription Name',
    accessor: 'subscriptionName',
    disableFilters: true,
  },
  {
    header: 'Account ID',
    accessor: 'accountId',
    disableFilters: true,
  },
  {
    header: 'Account Name',
    accessor: 'accountName',
    disableFilters: true,
  },
  {
    header: 'Cloud',
    accessor: 'cloudName',
    disableFilters: true,
  },
  {
    header: 'Region',
    accessor: 'regionName',
    disableFilters: true,
  },
];

export const cloudOptions = [
  {
    id: 'all',
    title: 'All',
  },
  {
    id: 'gcp',
    title: 'GCP',
  },
  {
    id: 'aws',
    title: 'AWS',
  },
];
