import * as R from 'ramda';
import moment from 'moment';
import { Dispatch } from 'redux';
import { useEffect, useRef } from 'react';

export const utcDateStartOfDay = moment.utc().startOf('day');

interface UpdateStateOnInputTypeProps {
  eTarget: { name: string; value: string; type: string; checked: boolean };
  dispatch: Dispatch;
  action: string;
  callback?: () => {};
}

export const updateStateOnInputType: (props: UpdateStateOnInputTypeProps) => void = ({
  eTarget: { name, value, type, checked },
  dispatch,
  action,
  callback,
}) => {
  const payload = {
    [name]: type === 'checkbox' ? checked : value || '',
  };

  dispatch({ type: action, payload });

  callback && callback();
};

export function debounceUtility(func, wait, immediate) {
  let timeout;

  return () => {
    // @ts-ignore
    const context = this;
    const args = arguments; // eslint-disable-line
    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);

    if (callNow) func.apply(context, args);
  };
}

const toStringToLower = (el) => {
  if (R.type(el) === 'String') {
    return R.toLower(el);
  }
  return R.toLower(R.toString(el));
};

export function findInObj(obj, elemToFind) {
  return R.map((elem) => {
    const toFind = toStringToLower(elemToFind);
    const findIn = toStringToLower(obj[elem]);

    return R.includes(toFind, findIn);
  }, R.keys(obj));
}

type IntervalFunction = () => ( unknown | void );

export function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = useRef<IntervalFunction| null>( null );
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    } return () => {};
  }, [callback, delay]);
}
