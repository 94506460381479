import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { showToast } from '../../components/Toast/Toast';
import { api, buildUrl } from '../../api/config';
import { SideBarLinkProps } from '../../components/MainSideBar/MainSideBar.types';

export const sidebarContractsItem: SideBarLinkProps = {
  title: 'Contracts',
  icon: IconNamesEnum.CONTRACTS,
  path: '/contracts',
};

export const sidebarManualAaItem: SideBarLinkProps = {
  title: 'Manual A-A',
  icon: IconNamesEnum.DEPLOYMENT,
  path: '/manualAa',
};

export const sidebarPocsItem: SideBarLinkProps = {
  title: 'POCs',
  icon: IconNamesEnum.POC,
  path: '/pocs',
};

export const sidebarCouponsItem: SideBarLinkProps = {
  title: 'Coupons',
  icon: IconNamesEnum.COUPONS,
  path: '/coupons',
};

export const auditLogsItem: SideBarLinkProps = {
  title: 'Audit Logs',
  icon: IconNamesEnum.LOG,
  path: '/auditLogs',
};

export const marketplaceContractsItem: SideBarLinkProps = {
  title: 'Marketplace Contracts',
  icon: IconNamesEnum.MARKETPLACE,
  path: '/marketplaceContracts',
};

export const sidebarAccountsMarketplacesItem: SideBarLinkProps = {
  title: 'Accounts Marketplaces',
  icon: IconNamesEnum.MARKETPLACE,
  path: '/accountsMarketplaces',
};

export const sidebarMaintenanceWindowItem: SideBarLinkProps = {
  title: 'Maintenance window',
  icon: IconNamesEnum.SETTINGS,
  path: '/maintenanceWindow/clusters',
};

export const topBarItemList = [];

export function displayErrors(err, projectSpecificMessage) {
  console.error({ ...err }); // eslint-disable-line
  console.error(projectSpecificMessage); // eslint-disable-line

  let message: string = 'Generic server error';

  if (err?.response) {
    message = `${
      err.response.data?.message ||
      err.response.statusText ||
      err.response.data ||
      `Generic server error ${err.response.status}`
    }`;
  }

  showToast(message);
}

const requestGetUserPermissions = () => api.get(`${buildUrl('bousers')}/session/permissions`);

export async function getUserPermissions(dispatch) {
  requestGetUserPermissions()
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({
          type: 'setPermissions',
          payload: data?.permissions,
        });
      }
    })
    .catch((err) => {
      displayErrors(err, "Failed trying to get user's permissions");
    });
}
