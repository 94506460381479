import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { GlobalStyle } from '@redislabsdev/redislabs-ui-components/styles';
import { theme } from '@redislabsdev/redis-ui-styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { api, buildUrl } from './api/config';
import store from './store';
import RootPageWrapper from './pages/rootPage/RootPageWrapper';

const queryClient = new QueryClient();

// css import
import 'react-day-picker/dist/style.css';
import '@redislabsdev/redislabs-ui-components/styles/ui-components-styles.css';
import '@redislabsdev/redis-ui-styles/normalized-styles.css';
import '@redislabsdev/redis-ui-styles/fonts.css';
import './styles/main.css';

const GlobalFontStyle = createGlobalStyle`
  body {
    font-family: ${theme.fontTypes.regular};
  }
`;

declare global {
  interface Window {
    store: object;
  }
}

if (process.env.NODE_ENV === 'development') {
  window.store = store.getState;
}

(async () => {
  try {
    const oktaConfig = await api.get(`${buildUrl('bousers')}/configuration/okta`);
    if (oktaConfig.status === 200) {
      // okta config object based on window.origin
      const oktaAuth = new OktaAuth({
        ...oktaConfig.data,
        redirectUri:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000/implicit/callback'
            : oktaConfig.data.redirectUri,
        scopes: ['openid', 'email', 'groups', 'profile'],
        pkce: true,
      });

      ReactDom.render(
        <Provider store={store}>
          <React.StrictMode>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <GlobalFontStyle />
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <RootPageWrapper oktaAuth={oktaAuth} />
                </QueryClientProvider>
              </BrowserRouter>
            </ThemeProvider>
          </React.StrictMode>
        </Provider>,
        document.getElementById('root')
      );
    }
  } catch (err) {
    console.error(err); // eslint-disable-line
    console.error('Failed trying to get configuration for Contracts Page'); // eslint-disable-line
    ReactDom.render(
      <div>There was a problem trying to get the Okta configuration.</div>,
      document.getElementById('root')
    );
  }
})();
