import React, { useEffect, useState } from 'react';
import {
  Button,
} from '@redislabsdev/redislabs-ui-components';
import { DownloadIcon } from '@redislabsdev/redis-ui-icons';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import moment from 'moment';
import * as CS from '../../../../styles/common.style';

import { downloadAllReports, downloadConcreteReport, getUsageReport } from '../ContractPage.utils';

import { UsageReport } from '../contractPage.types';
import { SpanDisplayFlexInline } from '../../../../styles/commonAccountDetails.style';
import * as S from './ViewUsageReportPopup.style';

const ViewUsageReportPopup = ({ contractId, customerName, showViewUsageReportModal, onClose }) => {
  const [reports, setReports] = useState<UsageReport[]>([]);

  useEffect(() => {
    if (showViewUsageReportModal) {
      getUsageReport(contractId, (data) => setReports(data.reverse()));
    }
  }, [showViewUsageReportModal, contractId]);

  return (
      <ConfirmationDialog
          title="Usage Report"
          isOpen={showViewUsageReportModal}
          cancelButtonLabel="Cancel"
          handleModalCancel={onClose}
          bottomLeftComponent={
        reports.length > 0 ? (
            <Button
                size="medium"
                variant="link"
                data-testid="button--download-all"
                onClick={() => downloadAllReports(contractId)}
          >
              <DownloadIcon size="L" />
              Download All
            </Button>
        ) : (
            <></>
        )
      }
    >
          <S.UsageReportPopupContainer>
              <S.UsageReportPopupRow>
                  <SpanDisplayFlexInline>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="contractId">
                          Contract ID
                          <CS.ViewValues>
                              {contractId}
                          </CS.ViewValues>
                      </label>
                  </SpanDisplayFlexInline>
                  <SpanDisplayFlexInline>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="customerName">
                          Customer name
                          <CS.ViewValues>
                              {customerName}
                          </CS.ViewValues>
                      </label>
                  </SpanDisplayFlexInline>
              </S.UsageReportPopupRow>
              <S.UsageReportList>
                  {reports.map((report) => (
                      <S.UsageReportListItem key={report.fileName}>
                          <CS.ViewValues>
                              {moment(report.reportMonth, 'YYYY-MM-DD').format('MMMM YYYY')}
                          </CS.ViewValues>
                          <Button
                              size="small"
                              variant="secondary"
                              data-testid="button--download-report"
                              onClick={() => downloadConcreteReport(contractId, report.reportMonth)}
              >
                            <DownloadIcon size="L" />
                          </Button>
                      </S.UsageReportListItem>
          ))}
              </S.UsageReportList>
          </S.UsageReportPopupContainer>
      </ConfirmationDialog>
  );
};

export default ViewUsageReportPopup;
