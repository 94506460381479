import reduceReducers from 'reduce-reducers';
import { StoreInterface } from '../interfaces/storeInterfaces';
import contractsPageReducer, { contractsPageDefaultState } from './contractsPageReducer';
import contractPageReducer, { contractPageDefaultState } from './contractPageReducer';
import marketplaceContractsPageReducer, {
  marketplaceContractsDefaultState,
} from './marketplaceContractsPageReducer';
import marketplaceContractPageReducer, {
  marketplaceContractDefaultState,
} from './marketplaceContractPageReducer';
import rootPageReducer, { rootPageDefaultState } from './rootPageReducer';
import manualAaPageReducer, { manualAaPageDefaultState } from './manualAaPageReducer';
import pocsPage, { pocsPageDefaultState } from './pocsPageReducer';
import auditLogsPageReducer, { auditLogsPageReducerDefaultState } from './auditLogsPageReducer';
import couponsPageReducer, { couponsPageDefaultState } from './couponsPageReducer';
import accountsMarketplacesPageReducer, { accountsMarketplacesPageDefaultState } from './accountsMarketplacesPageReducer';

const initialState: StoreInterface = {
  rootPage: rootPageDefaultState,
  contractsPage: contractsPageDefaultState,
  contractPage: contractPageDefaultState,
  marketplaceContracts: marketplaceContractsDefaultState,
  marketplaceContract: marketplaceContractDefaultState,
  manualAaPage: manualAaPageDefaultState,
  pocsPage: pocsPageDefaultState,
  couponsPage: couponsPageDefaultState,
  auditLogsPage: auditLogsPageReducerDefaultState,
  accountsMarketplacesPage: accountsMarketplacesPageDefaultState,
};

const reducer = reduceReducers(
  initialState,
  rootPageReducer,
  contractsPageReducer,
  contractPageReducer,
  marketplaceContractsPageReducer,
  marketplaceContractPageReducer,
  manualAaPageReducer,
  pocsPage,
  couponsPageReducer,
  auditLogsPageReducer,
  accountsMarketplacesPageReducer
);

export default reducer;
