import React from 'react';
import { TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  showToast as showOriginalToast,
  ToastContainer,
} from '@redislabsdev/redislabs-ui-components';

import * as I from './Toast.types';

export const showToast = (
  alertMsg: string,
  alertType: TypeOptions = 'error',
  options?: I.ToastTypes
): void => {
  const notificationOptions = { ...options, autoClose: 3000 };

  showOriginalToast(alertMsg, alertType, notificationOptions);
};

export default () => <ToastContainer />;
