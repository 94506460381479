import React from 'react';
import moment from 'moment';

import { theme } from '@redislabsdev/redis-ui-styles';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import * as CS from '../../styles/common.style';

export const auditLogsColumnNames = [
  {
    header: 'Time',
    accessor: 'timestamp',
    disableFilters: true,
    width: 10,
    Cell: ({ value }) => {
      return <div>{moment.utc(value).format('DD/MM/YYYY HH:mm')}</div>;
    },
  },
  {
    header: 'Originator',
    accessor: 'userEmail',
    disableFilters: true,
    disableSortBy: true,
    width: 20,
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
  {
    header: 'Resource',
    accessor: 'resource',
    width: 10,
    disableFilters: true,
  },
  {
    header: 'Description',
    accessor: 'description',
    width: 40,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
        <CS.TooltipWrapper>
          <Tooltip
            tooltipContent={<CS.TooltipTextWithTooltip>{value}</CS.TooltipTextWithTooltip>}
            placement="top"
            trigger="hover"
            textColor={theme.semantic.color.text.neutral700}
          >
            <CS.TextWithTooltip>{value}</CS.TextWithTooltip>
          </Tooltip>
        </CS.TooltipWrapper>
      );
    },
  },
  {
    header: 'Status',
    accessor: 'status',
    width: 10,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
];
