import React from 'react';
import * as CS from '../../styles/common.style';
import AccountsMarketplacesFilterSection from './AccountsMarketplacesPageFilterSection'
import AccountsMarketplacesPageTableSection from './AccountsMarketplacesPageTableSection';
import AccountMarketplaceStatusUpdateModal from './AccountMarketplaceStatusUpdateModal';

const AccountsMarketplacePage: React.FC = () => {
    return (
        <>
            <CS.PageTitleAndActions>
                <CS.JustifyToLeft>
                    <CS.PageHeaderTitle>
                        Marketplace To SM Accounts
                    </CS.PageHeaderTitle>
                </CS.JustifyToLeft>
            </CS.PageTitleAndActions>
            <AccountsMarketplacesFilterSection />
            <AccountsMarketplacesPageTableSection />
            <AccountMarketplaceStatusUpdateModal />
        </>
    );
};

export default AccountsMarketplacePage;
