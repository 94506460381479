/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  nameSchema,
  replicationSchema,
  sizeSchema,
  throughputSchema,
} from '../bdbsModal/bdbsModal.utils';

const useBdbErrorsHandler = () => {
  const [inputErrorsHandler, setInputErrorsHandler] = React.useState({
    bdbName: {
      error: '',
      validator: nameSchema(),
    },
    sizeGb: {
      error: '',
      validator: sizeSchema(),
    },
    maxThroughput: {
      error: '',
      validator: throughputSchema(),
    },
    replication: {
      error: '',
      validator: replicationSchema(),
    },
  });

  return { inputErrorsHandler, setInputErrorsHandler };
};

export default useBdbErrorsHandler;
