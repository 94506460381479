import { StoreInterface } from '../interfaces/storeInterfaces';

export const getMarketplaceContractLoadingMarketplaceContract = (state: StoreInterface) =>
  state.marketplaceContract.loadingMarketplaceContract;
export const getMarketplaceContracFormTouchedState = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceFormTouchedState;
export const getMarketplaceContractAccounts = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.accounts;
export const getMarketplaceContractAccountsOnEdit = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.accountsOnEdit;
export const getMarketplaceContractContractId = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.contractId;
export const getMarketplaceContractCustomerName = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.customerName;
export const getMarketplaceContractCustomerEmail = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.customerEmail;
export const getMarketplaceContractStatus = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.status;
export const getMarketplaceContractEnableAccountsOnEdit = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.enableAccountsEdit;
export const getMarketplaceContractRedislabsEmailList = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.redislabsEmailList;
export const getMarketplaceContractComment = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.comment;
export const getMarketplaceContractOnDemandChargeStart = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.onDemandChargeStart;
export const getMarketplaceContractContactName = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.contactName;
export const getExpandMarketplaceContractId = (state: StoreInterface) => 
  state.marketplaceContract.marketplaceContractInfo.expandContractId;
export const getMarketplaceContractStartDate = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.startDate;
export const getMarketplaceContractEndDate = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.endDate;
export const getMarketplaceContractCredit = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.credit;
export const getMarketplaceContractCreditConsumed = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.creditConsumed;
export const getMarketplaceContractOverUsage = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.overUsage;
export const getMarketplaceContractMarketplace = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.marketplace;
export const getMarketplaceContractShowAddAccountRow = (state: StoreInterface) =>
  state.marketplaceContract.marketplaceContractInfo.showAddAccountRow;

export const getMarketplaceContractAccountsUpdateInProgress = (state: StoreInterface) => {
  const accounts = getMarketplaceContractAccounts(state);
  let updateInProgress = false;
  for (const value of accounts.values()) {
    if (value.status !== 'active') {
      updateInProgress = true;
    }
  }
  return updateInProgress;
};

// TODO remove placeholder
export const getMarketplaceContractInfoValidations = (state: StoreInterface) => {
  const accountsOnEditValidation = () => {
    const accountsOnEdit = getMarketplaceContractAccountsOnEdit(state);
    return accountsOnEdit.length > 0 && accountsOnEdit[0].accountId !== 'placeholder';
  };
  return {
    customerEmail: state.marketplaceContract.marketplaceContractInfoValidation.customerEmail,
    redislabsEmailList:
      state.marketplaceContract.marketplaceContractInfoValidation.redislabsEmailList,
    credit: state.marketplaceContract.marketplaceContractInfoValidation.credit,
    accountsOnEdit: accountsOnEditValidation(),
  };
};
