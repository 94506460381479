import axios, { AxiosRequestConfig } from 'axios';
import { appMode, isProduction } from '../config';
import defaultBaseUrl from './defaultPaths';

const baseUrlDevMode = 'https://new-bo-qa.qa.redislabs.com/api';
const baseUrlProd = `${window.location.origin}/api`;

export const baseURL = isProduction ? baseUrlProd : baseUrlDevMode;

export function buildUrl(location) {
  return defaultBaseUrl[appMode][location];
}

function defaultOptions(): AxiosRequestConfig {
  return {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

function commonOptions(): AxiosRequestConfig {
  return {
    ...defaultOptions(),
    paramsSerializer(params) {
      return Object.entries(params)
        .map((param) => param.map((elem) => encodeURIComponent(elem as string)).join('='))
        .join('&');
    },
  };
}

function fileOptions(): AxiosRequestConfig {
  return {
    ...commonOptions(),
    responseType: 'blob',
  };
}

export const defaultApi = axios.create(defaultOptions());

export const api = axios.create(commonOptions());

export const fileApi = axios.create(fileOptions());
