import * as R from 'ramda';
import {
  defaultContractInfo,
  defaultFilterItems,
  defaultPageFilters,
  defaultGraceContractInfo,
} from '../constants/contractsPageConstants';

export const contractsPageDefaultState = {
  filterItems: defaultFilterItems,
  loadingContractsTableData: false,
  loadingConfig: true,
  loadingCountriesStates: true,
  contractsTableData: [],
  accountsConfiguration: {
    cloudContractStatuses: [{ code: '', label: 'All' }],
    countriesStates: [],
    paymentInfoConfig: [],
  },
  contractInfo: defaultContractInfo,
  touched: false,
  contractInfoPickedAccounts: {},
  contractInfoValidation: {
    customerEmail: true,
    credit: true,
    redislabsEmailList: true,
  },
  graceContractModalData: defaultGraceContractInfo,
};

// TODO: add correct interface and move contract specific actions to the contract reducer

export default function contractsPageReducer(state, action) {
  const { contractsPage } = state;

  switch (action.type) {
    case 'loadingContractsTableData':
      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          loadingContractsTableData: action.payload,
        },
      };

    case 'loadingCountriesStates':
      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          loadingCountriesStates: action.payload,
        },
      };

    case 'setContractsTableData': {
      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          contractsTableData: action.payload,
        },
      };
    }

    case 'setFilterItems': {
      const { filterItems } = contractsPage;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          filterItems: {
            ...filterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'resetPageFilterItems': {
      const { filterItems } = contractsPage;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          filterItems: {
            ...filterItems,
            ...defaultPageFilters,
          },
        },
      };
    }

    case 'clearIndividualDate': {
      const { filterItems } = contractsPage;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          filterItems: {
            ...filterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'contractsConfig': {
      const {
        accountsConfiguration,
        accountsConfiguration: { cloudContractStatuses },
      } = contractsPage;
      const newStatuses = action.payload.cloudContractStatuses;
      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          accountsConfiguration: {
            ...accountsConfiguration,
            cloudContractStatuses: [...cloudContractStatuses, ...newStatuses],
          },
          loadingConfig: false,
        },
      };
    }

    case 'paymentInfoConfig': {
      const {
        accountsConfiguration,
        accountsConfiguration: { paymentInfoConfig },
      } = contractsPage;
      const newStatuses = action.payload.paymentMethods;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          accountsConfiguration: {
            ...accountsConfiguration,
            paymentInfoConfig: [...paymentInfoConfig, ...newStatuses],
          },
        },
      };
    }

    case 'countriesStates': {
      const countriesStates = action.payload.countries;
      const { accountsConfiguration } = contractsPage;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          accountsConfiguration: {
            ...accountsConfiguration,
            countriesStates,
          },
        },
      };
    }

    case 'setFormTouchedState': {
      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          touched: action.payload,
        },
      };
    }

    case 'contractInfo': {
      const { contractInfo } = contractsPage;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          contractInfo: {
            ...contractInfo,
            ...action.payload,
          },
        },
      };
    }

    case 'addAccountToPayment': {
      const { contractInfo } = contractsPage;
      const { accWithDetails } = contractInfo;
      const newAccWithDetails: Map<string, object> = new Map(accWithDetails);
      const [key, value] = action.payload;

      newAccWithDetails.set(key, value);

      if (!R.contains('placeholder', action.payload)) {
        newAccWithDetails.delete('placeholder');
      }

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          contractInfo: {
            ...contractInfo,
            accWithDetails: newAccWithDetails,
          },
        },
      };
    }

    case 'removeAccountFromPayment': {
      const { contractInfo, contractInfoPickedAccounts } = contractsPage;
      const { accWithDetails } = contractInfo;
      const { accountId } = action.payload;

      const newPickedAcc = R.omit([accountId], contractInfoPickedAccounts);
      const newAccounts = accWithDetails;
      accWithDetails.delete(`${accountId}`);

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          contractInfo: {
            ...contractInfo,
            accWithDetails: new Map(newAccounts),
          },
          contractInfoPickedAccounts: {
            ...newPickedAcc,
          },
        },
      };
    }

    case 'contractInfoPickedAccounts': {
      const { contractInfoPickedAccounts } = contractsPage;
      const accId = Object.keys(action.payload)[0];

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          contractInfoPickedAccounts: {
            ...contractInfoPickedAccounts,
            [accId]: {
              ...contractInfoPickedAccounts[accId],
              ...action.payload[accId],
            },
          },
        },
      };
    }

    case 'resetContractInfo': {
      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          touched: false,
          contractInfo: {
            ...defaultContractInfo,
            accounts: new Map(),
            accWithDetails: new Map(),
          },
          contractInfoPickedAccounts: {},
          contractInfoValidation: {
            ...contractsPageDefaultState.contractInfoValidation,
          },
        },
      };
    }

    case 'clearIndividualContractInfoDate': {
      const { contractInfo } = contractsPage;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          contractInfo: {
            ...contractInfo,
            ...action.payload,
          },
        },
      };
    }

    case 'contractInfoValidation': {
      const { contractInfoValidation } = contractsPage;

      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          contractInfoValidation: {
            ...contractInfoValidation,
            ...action.payload,
          },
        },
      };
    }

    case 'setGraceContractModalData': {
      return {
        ...state,
        contractsPage: {
          ...contractsPage,
          graceContractModalData: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
