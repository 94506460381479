import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as S from '../../rootPage/RootPage.style';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import { getMarketplaceAccountsConf } from '../MarketplaceContracts.api';
import { getMarketplaceContractLoadingMarketplaceContract } from '../../../selectors/MarketplaceContractPageSelectors';
import McpTitleSection from './McpTitleSection';
import McpInfoSection from './McpInfoSection';
import McpAccountsSectionWrapper from './McpAccountsSectionWrapper';

import { getOneMarketplaceContract } from './MarketplaceContract.api';

const MarketplaceContractPage: React.FC = () => {
  const dispatch = useDispatch();
  const { id: contractId } = useParams<{id: string}>();

  const loadingMarketplaceContract = useSelector(
      getMarketplaceContractLoadingMarketplaceContract
  );

  const marketplaceContractsStatuses = useSelector(
    (state: StoreInterface) => state.marketplaceContracts.marketplaceContractsStatuses
  );

  useEffect(() => {
    dispatch({ type: 'resetMarketplaceContractInfo' });
  }, []); // eslint-disable-line

  useEffect(() => {
    const callFun = async () => {
      if (marketplaceContractsStatuses.length <= 1) {
        await getMarketplaceAccountsConf(dispatch);
      }

      if (contractId) {
        await getOneMarketplaceContract(contractId, dispatch);
      }
    };

    callFun();
  }, []); // eslint-disable-line

  if (loadingMarketplaceContract)
    return (<S.LoadingWrapper>
        Loading... Please wait.
    </S.LoadingWrapper>);

  return (
      <>
          <McpTitleSection />

          <McpInfoSection />

          <McpAccountsSectionWrapper />
      </>
  );
};

export default MarketplaceContractPage;
