import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@redislabsdev/redislabs-ui-components';

import * as CS from '../../styles/common.style';

import { COUPONS_WRITE } from '../../constants/permissionsConstants';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import CouponPopup from './couponPopup/CouponPopup';
import CouponsPageFilterSection from './CouponsPageFilterSection';
import CouponsPageTableSection from './CouponsPageTableSection';

const CouponsPage: React.FC = () => {
  const dispatch = useDispatch();

  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

  const canCreateCoupons = permissions.includes(COUPONS_WRITE);

  return (
      <>
          <CS.PageTitleAndActions>
              <CS.JustifyToLeft>
                  <CS.PageHeaderTitle>
                      Coupons
                  </CS.PageHeaderTitle>
              </CS.JustifyToLeft>
              <CS.JustifyToRight>
                  {canCreateCoupons && (
                  <Button
                      data-testid="button--new-coupon"
                      onClick={() => dispatch({ type: 'setShowCouponModal', payload: true })}
            >
                      New Coupon
                  </Button>
          )}
              </CS.JustifyToRight>
          </CS.PageTitleAndActions>
          <CouponsPageFilterSection />
          <CouponsPageTableSection />
          <CouponPopup />
      </>
  );
};
export default CouponsPage;
