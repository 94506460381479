import React, { useEffect } from 'react';
import {
  Button,
  IconNamesEnum,
  SvgIcon,
  Loader,
  Tooltip
} from '@redislabsdev/redislabs-ui-components';
import { ShowIcon } from '@redislabsdev/redis-ui-icons';
import * as S from '../../../styles/commonAccountDetails.style';
import ViewUsageReportPopup from '../../contractsPage/contractPage/viewUsageReport/ViewUsageReportPopup';
import { useInterval } from '../../../utils/common.utils';
import { Account } from '../../../interfaces/marketplaceContractsStore.type';
import McpRenderAnAccount from './McpRenderAnAccount';
import McpAddAccount from './McpAddAccount';

const defaultItem = {
  id: 1234,
  icon: IconNamesEnum.AMAZON,
  text: 'Amazon Web Services',
};

interface McpAccountsSectionProps {
  accounts: Account[];
  accountsOnEdit: Account[];
  contractId: number;
  customerName: string;
  status: string;
  accountsUpdateInProgress: boolean;
  enableAccountsEdit: boolean;
  showAddAccountRow: boolean;
  pageViewMode: boolean;
  accountsUpdateInterval: number;
  resetAccountsOnEdit: () => void;
  onAddRow: () => void;
  onAddAccount: (accountId:number | string) => void;
  onRemoveAccount: (accountId: number | string) => void;
  onUpdateAccounts: () => Promise<void>;
  showViewUsageReportModal: boolean;
  onShowUsageReport: () => void;
  onCloseUsageReport: () => void;
}
const McpAccountsSection: React.FC<McpAccountsSectionProps> = ({
                                                                 accounts,
                                                                 accountsOnEdit,
                                                                 contractId,
                                                                 customerName,
                                                                 status,
                                                                 accountsUpdateInProgress,
                                                                 enableAccountsEdit,
                                                                 showAddAccountRow,
                                                                 pageViewMode,
                                                                 accountsUpdateInterval,
                                                                 resetAccountsOnEdit,
                                                                 onAddRow,
                                                                 onAddAccount,
                                                                 onRemoveAccount,
                                                                 onUpdateAccounts,
                                                                 showViewUsageReportModal,
                                                                 onShowUsageReport,
                                                                 onCloseUsageReport
                                                               }) => {
  const accountsViewMode = pageViewMode || accountsUpdateInProgress || !enableAccountsEdit ;
  const accountsToRender = accountsViewMode ? accounts : accountsOnEdit;

 // If on 'edit' mode, reset thee account list to be similar to the accounts on view mode
  useEffect(() => {
    (!pageViewMode) && resetAccountsOnEdit();
  }, [accounts.length, pageViewMode]);


  const updateAccounts = async () => {
    if (accountsViewMode && status === 'active') {
      await onUpdateAccounts();
    }
  };

  // Poll for changes on the contract's accounts every few seconds
  useInterval(updateAccounts, accountsUpdateInterval);

  const addAccount = (accountId) => {
    const containsId = accountsToRender && accountsToRender.some((acc) =>
        acc.accountId.toString() === accountId);
    !containsId && onAddAccount(accountId);
  };

  return (
    <>
      <S.SmAccountsSectionStyle>
        <S.SectionTitle>
          SM Accounts
          {accountsUpdateInProgress &&
          <Tooltip tooltipContent='Update in progress' textColor="#01112a">
            {' '}
            <Loader size='20' />
            {' '}
          </Tooltip>}
          {accountsViewMode ? (
            <Button
              data-testid="button--view-usage-report"
              aria-label="view usage report"
              variant="link"
              onClick={onShowUsageReport}
            >
              <ShowIcon size="M" />
              <span>View usage report</span>
            </Button>
              ) : (
                <Button
                  data-testid="button--add-account"
                  aria-label="add account"
                  variant="primary"
                  onClick={onAddRow}
                >
                  <SvgIcon width="20" height="20" name={IconNamesEnum.PLUS} fill="white" />
                </Button>
          ) }
        </S.SectionTitle>
        <S.SpanDisplayFlexInline>
          <S.SMAccountsTitle>Account number</S.SMAccountsTitle>
          <S.SeparatorForTitle />
          <S.SMAccountsTitle>Account name</S.SMAccountsTitle>
          <S.SeparatorForTitle />
          <S.SMAccountsTitle>Payment method</S.SMAccountsTitle>
        </S.SpanDisplayFlexInline>

        <>
          {accountsToRender.map((account) => {
            const accDefaultPayOption = defaultItem;

            return (
              <McpRenderAnAccount
                key={account.accountId}
                account={account}
                view={accountsViewMode}
                accDefaultPayOption={accDefaultPayOption}
                onRemoveAccount={onRemoveAccount}
              />
            );
          })}
          { showAddAccountRow && <McpAddAccount
            onAddAccount={addAccount}
          /> }
        </>
      </S.SmAccountsSectionStyle>
      <ViewUsageReportPopup
        contractId={contractId}
        customerName={customerName}
        showViewUsageReportModal={showViewUsageReportModal}
        onClose={onCloseUsageReport}
      />
      </>
  );
};

export default McpAccountsSection;
