import moment from 'moment';
import { api, buildUrl } from '../../api/config';
import { displayErrors } from '../rootPage/RootPage.utils';
import { prepareFilterTableRequestData } from '../../utils/filterTable.utils';

const getAuditLogsTableDataApiRequest = (dataToSend) =>
  api.get(`${buildUrl('audit')}/search/fulltext`, {
    params: dataToSend,
  });

export async function getAuditLogsTableData(reqParams, dispatch) {
  dispatch({ type: 'setLoadingAuditLogsTableData', payload: true });
  dispatch({
    type: 'setAuditLogsFilterItems',
    payload: {
      pageIndex: reqParams.pageIndex,
      pageSize: reqParams.pageSize,
      offset: reqParams.offset,
      sortBy: reqParams.sortBy,
      sortDirection: reqParams.sortDirection,
    },
  });

  const newParams = {
    ...reqParams,
    timestamp: `${reqParams.startDateFrom.valueOf()} ${moment
      .utc(reqParams.startDateTo)
      .endOf('day')
      .valueOf()}`,
    q: reqParams.queryText,
  };

  getAuditLogsTableDataApiRequest(
    prepareFilterTableRequestData(newParams, [
      'pageCount',
      'pageIndex',
      'startDateFrom',
      'startDateTo',
      'queryText',
    ])
  )
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({
          type: 'setAuditLogsTableData',
          payload: data.items,
        });

        dispatch({
          type: 'setAuditLogsFilterItems',
          payload: {
            pageCount: Math.ceil(data.itemsTotal / reqParams.pageSize),
          },
        });

        dispatch({ type: 'setLoadingAuditLogsTableData', payload: false });
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to get Audit Logs table data');
    });
}
