import { AxiosResponse } from 'axios';
import { api, buildUrl } from '../../api/config';
import { displayErrors } from '../rootPage/RootPage.utils';
import { prepareFilterTableRequestData } from '../../utils/filterTable.utils';

export async function getAccountsMarketplacesConfiguration(dispatch): Promise<void> {
  try {
    const response = await api.get(`${buildUrl('bousers')}/configuration/account-marketplace`);

    if (response.status === 200) {
      dispatch({ type: 'setAccountsMarketplacesStatuses', payload: response.data.statuses });
      dispatch({ type: 'setLoadingAccountMarketplaceConfig', payload: false });
      return response.data;
    }
  } catch (err) {
    displayErrors(err, 'Failed trying to get statuses for Accounts Marketplaces Page');
  }
}

export async function getAccountsMarketplacesTableData(reqParams, dispatch): Promise<void> {
  dispatch({ type: 'setLoadingAccountsMarketplacesTableData', payload: true });
  dispatch({
    type: 'setAccountsMarketplacesFilterItems',
    payload: {
      pageIndex: reqParams.pageIndex,
      pageSize: reqParams.pageSize,
      offset: reqParams.offset,
      sortBy: reqParams.sortBy,
      sortDirection: reqParams.sortDirection,
    },
  });

  const response = await api.get(buildUrl('accountsMarketplaces'), {
    params: prepareFilterTableRequestData(reqParams, ['pageCount', 'pageIndex']),
  });

  if (response.status === 200) {
    dispatch({
      type: 'setAccountsMarketplacesFilterItems',
      payload: {
        pageCount: Math.ceil(response.data.itemsTotal / reqParams.pageSize),
      },
    });

    dispatch({
      type: 'setAccountsMarketplacesTableData',
      payload: response.data.items,
    });
  }
  dispatch({ type: 'setLoadingAccountsMarketplacesTableData', payload: false });
}

export async function updateAccountMarketplaceStatus(reqParams):
  Promise<AxiosResponse<object> | null> {
  const { accountMarketplaceId, status } = reqParams;
  try {
    const response = await api.put(`${buildUrl('accountsMarketplaces')}/${accountMarketplaceId}/status`, { status });
    return response;
  } catch (err) {
    displayErrors(err, 'There was a problem while trying to update account marketplace status');
  }
  return null;
} 