import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from 'react-router-dom';
import {
    getMarketplaceContractAccounts,
    getMarketplaceContractAccountsOnEdit,
    getMarketplaceContractAccountsUpdateInProgress,
    getMarketplaceContractContractId,
    getMarketplaceContractEnableAccountsOnEdit,
    getMarketplaceContractShowAddAccountRow,
    getMarketplaceContractStatus
} from "../../../selectors/MarketplaceContractPageSelectors";
import { getShowViewUsageReportModal } from "../../../selectors/ContractPageSelectors";
import McpAccountsSection from "./McpAccountsSection";
import { addAccountWithDetails, updateMarketplaceContractAccounts } from "./MarketplaceContract.api";

const ACCOUNTS_UPDATE_INTERVAL = 5000;

const McpAccountsSectionWrapper: React.FC = () => {
    const dispatch = useDispatch();

    const accounts = useSelector(getMarketplaceContractAccounts);
    const accountsOnEdit = useSelector(getMarketplaceContractAccountsOnEdit);
    const contractId = useSelector(getMarketplaceContractContractId);
    const customerName = useSelector(getMarketplaceContractStatus);
    const status = useSelector(getMarketplaceContractStatus);
    const accountsUpdateInProgress = useSelector(getMarketplaceContractAccountsUpdateInProgress);
    const enableAccountsEdit = useSelector(getMarketplaceContractEnableAccountsOnEdit);
    const showAddAccountRow = useSelector(getMarketplaceContractShowAddAccountRow);
    const showViewUsageReportModal = useSelector(getShowViewUsageReportModal);
    const pageViewMode = useRouteMatch('/marketplaceContracts/:id/view')?.isExact ? true : false;

    return <McpAccountsSection
    accounts={accounts}
    accountsOnEdit={accountsOnEdit}
    contractId={contractId}
    customerName={customerName}
    status={status}
    accountsUpdateInProgress={accountsUpdateInProgress}
    enableAccountsEdit={enableAccountsEdit}
    showAddAccountRow={showAddAccountRow}
    resetAccountsOnEdit={() => {
        dispatch({ type: 'resetMarketplaceContractAccountsOnEdit', payload: accounts });
    }}
    onAddRow={() => {
        dispatch( { type:'setMarketplaceContractShowAddAccountRow', payload: true });
    }}
    pageViewMode={pageViewMode}
    accountsUpdateInterval={ACCOUNTS_UPDATE_INTERVAL}
    onAddAccount={(accountId) =>
        addAccountWithDetails(accountId, dispatch)
    }
    onRemoveAccount={( accountId ) => {
        dispatch({
            type: 'removeMarketplaceContractAccountOnEdit',
            payload: accountId
        });
        // used to set the form's touch state
        dispatch({
            type: 'setMarketplaceFormTouchedState',
            payload: true,
        });
    }}
    onUpdateAccounts={ async () =>
        await updateMarketplaceContractAccounts( contractId, dispatch)
    }
    showViewUsageReportModal={showViewUsageReportModal}
    onShowUsageReport={() =>
        dispatch({ type: 'showViewUsageReportModal', payload: true })
    }
    onCloseUsageReport={() =>
        dispatch({ type: 'showViewUsageReportModal', payload: false })
    }
    />

}

export default McpAccountsSectionWrapper;