import * as I from '../pages/manualAaPage/ManualAa.types';

const defaultFilterItems: I.FilterItems = {
  subscriptionId: '',
  accountId: '',
  accountName: '',
  cloudName: '',
  regionName: '',
};

const defaultModalValues = {
  showModal: false,
  subscriptionId: '',
  loadingBdbsTableData: false,
  bdbsTableData: [],
};

export const manualAaPageDefaultState = {
  filterItems: defaultFilterItems,
  loadingManualAaTableData: false,
  manualAaTableData: [],
  bdbsModalValues: defaultModalValues,
};

export default function manualAaPageReducer(state, action) {
  const { manualAaPage } = state;

  switch (action.type) {
    case 'setManualAaFilterItems': {
      const { filterItems } = manualAaPage;

      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          filterItems: {
            ...filterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'resetManualAaFilterItems':
      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          filterItems: defaultFilterItems,
        },
      };

    case 'setLoadingManualAaTableData':
      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          loadingManualAaTableData: action.payload,
        },
      };

    case 'setManualAaTableData':
      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          manualAaTableData: action.payload,
        },
      };

    case 'setBdbsModalValues': {
      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          bdbsModalValues: {
            ...manualAaPage.bdbsModalValues,
            showModal: true,
            subscriptionId: action.payload,
          },
        },
      };
    }

    case 'resetBdbsModalValues':
      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          bdbsModalValues: defaultModalValues,
        },
      };

    case 'loadingBdbsTableData':
      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          bdbsModalValues: {
            ...manualAaPage.bdbsModalValues,
            loadingBdbsTableData: action.payload,
          },
        },
      };

    case 'setBdbsTableData':
      return {
        ...state,
        manualAaPage: {
          ...manualAaPage,
          bdbsModalValues: {
            ...manualAaPage.bdbsModalValues,
            bdbsTableData: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
