const emailList =
  'One of the entered emails is not correct. The list must contain at least one email address.' +
  ' Separate email addresses using commas: example@email.com, anotherExample@email.co, etc.';

export const errorsMessages = {
  customerEmail: 'Email address is invalid',
  credit: 'Maximum allowed value is 100,000,000.000',
  redislabsEmailList: emailList,
  newPocEmailList: emailList,
  couponCode: 'A coupon must not contain white spaces and it should have maximum 20 characters.',
  couponAmount: 'Minimum amount is 0.001 and maximum amount is 999999999.999',
  couponMaxUses: 'Minimum amount is 1 and maximum amount is 2147483647.',
  creditExpirationPeriod: 'Minimum value is 1 and maximum value is 36 and must be an integer.',
};
