import {
  DefaultMarketplaceContractInfoInterface,
  MarketplaceContractInterface,
} from '../interfaces/marketplaceContractsStore.type';

const defaultMarketplaceContractInfo: DefaultMarketplaceContractInfoInterface = {
  accounts: [],
  accountsOnEdit: [],
  contactName: '',
  contractId: 0,
  credit: 0,
  creditConsumed: '',
  overUsage: '',
  customerEmail: '',
  customerName: '',
  endDate: null,
  expandContractId: null,
  marketplace: '',
  onDemandChargeStart: null,
  redislabsEmailList: [''],
  startDate: null,
  status: '',
  comment: '',
  enableAccountsEdit: false,
  showAddAccountRow: false,
};

export const marketplaceContractDefaultState: MarketplaceContractInterface = {
  marketplaceContractInfo: defaultMarketplaceContractInfo,
  loadingMarketplaceContract: true,
  marketplaceFormTouchedState: false,
  marketplaceContractInfoValidation: {
    customerEmail: true,
    redislabsEmailList: true,
    credit: true,
  },
};

export default function marketplaceContractPageReducer(state, action) {
  const { marketplaceContract }: { marketplaceContract: MarketplaceContractInterface } = state;

  switch (action.type) {
    case 'resetMarketplaceContractInfo':
      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfo: defaultMarketplaceContractInfo,
          marketplaceFormTouchedState: false,
          marketplaceContractInfoValidation: marketplaceContract.marketplaceContractInfoValidation,
        },
      };

    case 'setMarketplaceContractInfo': {
      const { marketplaceContractInfo } = marketplaceContract;
      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfo: { ...marketplaceContractInfo, ...action.payload },
        },
      };
    }

    case 'setLoadingMarketplaceContract':
      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          loadingMarketplaceContract: action.payload,
        },
      };

    case 'setMarketplaceFormTouchedState':
      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceFormTouchedState: action.payload,
        },
      };

    case 'setMarketplaceContractInfoValidation': {
      const { marketplaceContractInfoValidation } = marketplaceContract;

      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfoValidation: {
            ...marketplaceContractInfoValidation,
            ...action.payload,
          },
        },
      };
    }

    case 'addMarketplaceContractAccountOnEdit': {
      const { marketplaceContractInfo } = marketplaceContract;
      const { accountsOnEdit } = marketplaceContractInfo;
      const newAccountsOnEdit = [...accountsOnEdit, action.payload];

      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfo: {
            ...marketplaceContractInfo,
            accountsOnEdit: newAccountsOnEdit,
          },
        },
      };
    }

    case 'setMarketplaceContractAccounts': {
      const { marketplaceContractInfo } = marketplaceContract;
      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfo: {
            ...marketplaceContractInfo,
            accounts: action.payload,
          },
        },
      };
    }

    case 'removeMarketplaceContractAccountOnEdit': {
      const { marketplaceContractInfo } = marketplaceContract;
      const { accountsOnEdit } = marketplaceContractInfo;
      const accountIdToRemove = action.payload;

      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfo: {
            ...marketplaceContractInfo,
            accountsOnEdit: accountsOnEdit.filter((acc) => acc.accountId !== accountIdToRemove),
          },
        },
      };
    }

    case 'resetMarketplaceContractAccountsOnEdit': {
      const { marketplaceContractInfo } = marketplaceContract;

      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfo: {
            ...marketplaceContractInfo,
            accountsOnEdit: action.payload,
          },
        },
      };
    }

    case 'setMarketplaceContractShowAddAccountRow': {
      const { marketplaceContractInfo } = marketplaceContract;
      return {
        ...state,
        marketplaceContract: {
          ...marketplaceContract,
          marketplaceContractInfo: {
            ...marketplaceContractInfo,
            showAddAccountRow: action.payload,
          },
        },
      };
    }

    default:
      return state;
  }
}
