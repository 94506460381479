import * as R from 'ramda';
import { saveWireTransferDetails } from '../../contractsPage.utils';

interface DefaultModalValues {
  accountId: string;
  showModal: boolean;
  paymentInfoId?: null | number | string;
}

export const defaultModalValues: DefaultModalValues = {
  accountId: '',
  showModal: false,
  paymentInfoId: null,
};

export const wireTransferTitle = {
  viewWire: 'View Wire Transfer',
  addWire: 'Add Wire Transfer',
  editWire: 'Edit Wire Transfer',
};

export const defaultStatesFromCountry = {
  stateCode: [],
  billStateCode: [],
};

export const accountAddress = {
  address1: '',
  address2: '',
  city: '',
  zip: '',
  countryCode: '',
  stateCode: '',
  vatCode: '',
  companyTaxId: '',
};

export const notMandatory = ['vatCode', 'address2', 'billAddress2'];

export function canSaveWire(obj, sameAddressAsAccount): boolean {
  let newObj = obj;

  if (sameAddressAsAccount) {
    newObj = R.pick(R.keys(accountAddress), newObj);
  }

  if (obj.countryCode !== 'us' && obj.countryCode !== 'ca') {
    newObj = R.omit(['stateCode'], newObj);
  }

  if (obj.billCountryCode !== 'us' && obj.billCountryCode !== 'ca') {
    newObj = R.omit(['billStateCode'], newObj);
  }

  if (obj.countryCode !== 'il') {
    newObj = R.omit(['companyTaxId'], newObj);
  }

  newObj = R.omit(notMandatory, newObj);

  return R.isEmpty(R.filter(R.isEmpty, newObj));
}

export function setPaymentOption(pickedAccId, itemId, dispatch) {
  dispatch({
    type: 'contractInfoPickedAccounts',
    payload: {
      [pickedAccId]: {
        accountId: pickedAccId,
        paymentInfoId: itemId,
      },
    },
  });
}

export function setTemporaryPaymentOption(pickedAccId, itemId, dispatch) {
  dispatch({
    type: 'contractInfoPickedAccounts',
    payload: {
      [pickedAccId]: {
        accountId: pickedAccId,
        tempPaymentInfoId: itemId,
      },
    },
  });
}

export function saveFormAction(props) {
  const {
    wireTransferFormData,
    sameAccAddAsBill,
    viewType,
    accountId,
    paymentInfoId,
    dispatch,
  } = props;
  const {
    address1,
    address2,
    city,
    zip,
    countryCode,
    stateCode,
    billAddress1,
    billAddress2,
    billCity,
    billZip,
    billCountryCode,
    billStateCode,
    companyTaxId,
    vatCode,
  } = wireTransferFormData;

  const accAdd = {
    address1: address1 || null,
    address2: address2 || null,
    city: city || null,
    zip: zip || null,
    countryCode: countryCode || null,
    stateCode: stateCode || null,
  };

  const billAdd = {
    address1: billAddress1 || null,
    address2: billAddress2 || null,
    city: billCity || null,
    zip: billZip || null,
    countryCode: billCountryCode || null,
    stateCode: billStateCode || null,
  };

  const newWireTransfer = {
    method: 'wired-transfer',
    accountAddress: accAdd,
    billingAddress: sameAccAddAsBill ? accAdd : billAdd,
    companyTaxId: companyTaxId || null,
    vatCode: vatCode || null,
  };

  saveWireTransferDetails({
    accountId,
    viewType,
    paymentInfoId,
    newWireTransfer,
    dispatch,
  });
}

export function getLabelForWireTransfer(configObjectArray) {
  const item = R.find(R.propEq('code', 'wired-transfer'), configObjectArray);

  return item?.label;
}
