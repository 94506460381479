import React from 'react';

const UsersPage: React.FC = () => {
  return (
      <>
          <div className="content-header">
              Users Page
          </div>
      </>
  );
};

export default UsersPage;
