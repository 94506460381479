/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IManualAaBdb } from '../ManualAa.types';

const useBdbs = (bdbs, idInEditMode) => {
  const [data, setData] = React.useState<IManualAaBdb[]>([]);

  React.useEffect(() => {
    if (bdbs && !idInEditMode) {
      setData(bdbs);
    }
  }, [bdbs, idInEditMode]);

  return { data, setData };
};

export default useBdbs;
