import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  text-align: center;
  padding: 10rem;
  font-size: 2rem;
`;

const HomePage: React.FC = () => {
  return (<StyledDiv>
      Please select a category from the left menu
  </StyledDiv>);
};

export default HomePage;
