import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button } from '@redislabsdev/redislabs-ui-components';

import * as CS from '../../styles/common.style';

import { CONTRACTS_WRITE } from '../../constants/permissionsConstants';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import ContractsPageFilterSection from './ContractsPageFilterSection';
import GraceContractPopup from './graceContract/GraceContractPopup';
import ContractsPageTableSection from './ContractsPageTableSection';

const ContractsPage: React.FC = () => {
  const history = useHistory();

  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

  const canWriteContracts = permissions.includes(CONTRACTS_WRITE);

  return (
      <>
          <CS.PageTitleAndActions>
              <CS.JustifyToLeft>
                  <CS.PageHeaderTitle>
                      Contracts
                  </CS.PageHeaderTitle>
              </CS.JustifyToLeft>
              <CS.JustifyToRight>
                  {canWriteContracts && (
                  <Button
                      data-testid="button--new-contract"
                      onClick={() => history.push('/contracts/new')}
            >
                      New Contract
                  </Button>
          )}
              </CS.JustifyToRight>
          </CS.PageTitleAndActions>

          <ContractsPageFilterSection />

          <ContractsPageTableSection />

          <GraceContractPopup />
      </>
  );
};

export default ContractsPage;
