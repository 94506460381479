import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './LogInPage.styles';

const LogInPage: React.FC = () => {
  return (
    <S.LoginPageWrapper>
      <S.LoginPageCenterContent>
        <div>
          <S.RedisIcon />
          <div>NEW BACKOFFICE</div>
        </div>

        <S.LinkWrapper>
          <Link to="/home">Go to LogIn page</Link>
        </S.LinkWrapper>
      </S.LoginPageCenterContent>
    </S.LoginPageWrapper>
  );
};

export default LogInPage;
