import {
  couponsFilterItemsDefaultValues,
  couponPopupDataDefaultValues,
  couponsPageFilterItemsDefaultValues,
} from '../constants/couponsPageConstants';

export const couponsPageDefaultState = {
  couponsFilterItems: couponsFilterItemsDefaultValues,
  couponsTableData: [],
  loadingCouponsTableData: false,
  showCouponModal: false,
  couponPopupData: couponPopupDataDefaultValues,
  newCouponValidation: {
    isCouponCodeValid: true,
    isCouponAmountValid: true,
    isCouponMaxUsesValid: true,
    isCreditExpirationValid: true,
  },
  couponStatuses: [{ code: '', label: 'All' }],
  couponCreditsExpirationPeriodMandatory: true,
  couponsPhase2Enabled: false,
  couponCustomerRetentionEnabled: false,
  loadCouponConfig: true,
};

export default function couponsPageReducer(state, action) {
  const { couponsPage } = state;

  switch (action.type) {
    case 'setLoadingCouponsTableData':
      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          loadingCouponsTableData: action.payload,
        },
      };

    case 'setCouponsFilterItems': {
      const { couponsFilterItems } = couponsPage;

      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          couponsFilterItems: {
            ...couponsFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'setCouponsTableData': {
      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          couponsTableData: action.payload,
        },
      };
    }

    case 'clearIndividualDate': {
      const { couponsFilterItems } = couponsPage;

      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          couponsFilterItems: {
            ...couponsFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'resetCouponsPageFilterItems': {
      const { couponsFilterItems } = couponsPage;

      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          couponsFilterItems: {
            ...couponsFilterItems,
            ...couponsPageFilterItemsDefaultValues,
          },
        },
      };
    }

    case 'setShowCouponModal': {
      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          showCouponModal: action.payload,
        },
      };
    }

    case 'setCouponConfiguration': {
      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          ...action.payload,
        },
      };
    }

    case 'setLoadCouponConfig': {
      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          loadCouponConfig: action.payload,
        },
      };
    }

    case 'setCouponPopupData': {
      const { couponPopupData } = couponsPage;

      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          couponPopupData: {
            ...couponPopupData,
            ...action.payload,
          },
        },
      };
    }

    case 'setNewCouponValidation': {
      const { newCouponValidation } = couponsPage;

      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          newCouponValidation: {
            ...newCouponValidation,
            ...action.payload,
          },
        },
      };
    }

    case 'resetNewCouponValidation': {
      return {
        ...state,
        couponsPage: {
          ...couponsPage,
          couponPopupData: couponPopupDataDefaultValues,
          newCouponValidation: couponsPageDefaultState.newCouponValidation,
        },
      };
    }

    default:
      return state;
  }
}
