import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '@redislabsdev/redislabs-ui-components';
import * as CS from '../../styles/common.style';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { COUPONS_TABLE_DEFAULT_SORT } from '../../constants/couponsPageConstants';
import { getCouponsColumns } from './CouponsPage.utils';
import { getCouponsTableData } from './CouponsPage.api';

const CouponsPageTableSection: React.FC = () => {
  const dispatch = useDispatch();
  const couponsFilterItems = useSelector(
    (state: StoreInterface) => state.couponsPage.couponsFilterItems
  );
  const couponsTableData = useSelector(
    (state: StoreInterface) => state.couponsPage.couponsTableData
  );
  const loadingCouponsTableData = useSelector(
    (state: StoreInterface) => state.couponsPage.loadingCouponsTableData
  );
  const couponsPhase2Enabled = useSelector(
    (state: StoreInterface) => state.couponsPage.couponsPhase2Enabled
  );
  const { pageSize, pageCount, pageIndex } = couponsFilterItems;
  const fetchData = useCallback(
    ({ pageIndex: internalPageIndex, pageSize: internalPageSize, sortBy: internalSortBy }) => {
      const { id, desc } = internalSortBy[0];
      getCouponsTableData(
        {
          ...couponsFilterItems,
          pageIndex: internalPageIndex || pageIndex,
          pageSize: internalPageSize || pageSize,
          offset: internalPageIndex * internalPageSize,
          sortBy: id,
          sortDirection: desc ? 'desc' : 'asc',
        },
        dispatch
      );
    },
    [pageSize, pageIndex, couponsFilterItems] // eslint-disable-line
  );
  const controlledPaginationProps = useMemo(() => {
    return {
      loading: loadingCouponsTableData,
      pageCount,
      fetchData,
      pageIndex,
    };
  }, [pageIndex, pageCount, loadingCouponsTableData, fetchData]);
  const couponsColumns = useMemo(() => getCouponsColumns(couponsPhase2Enabled), [
    couponsPhase2Enabled,
  ]);

  return (
      <CS.TableWrapper>
          <Table
              columns={couponsColumns}
              data={couponsTableData}
              manualSortBy
              skipReset
              initialPageSize={pageSize}
              initialSortBy={COUPONS_TABLE_DEFAULT_SORT}
              controlledPaginationProps={controlledPaginationProps}
      />
      </CS.TableWrapper>
  );
};

export default CouponsPageTableSection;
