import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import * as R from 'ramda';

import { getPocsTableData, stopPoc } from '../PocsPage.api';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import * as S from './StopPocPopup.style';

const StopPocPopup: React.FC = () => {
  const dispatch = useDispatch();

  const pocsFilterItems = useSelector((state: StoreInterface) => state.pocsPage.pocsFilterItems);
  const stopPocModalData = useSelector((state: StoreInterface) => state.pocsPage.stopPocModalData);

  // @ts-ignore
  const { pocId } = stopPocModalData; // TODO: add correct types

  return (
    <ConfirmationDialog
      title="Are you sure?"
      isOpen={!R.isEmpty(stopPocModalData)}
      cancelButtonLabel="Cancel"
      submitButtonLabel="Stop POC"
      handleModalCancel={() => {
        dispatch({ type: 'setStopPocModalData', payload: {} });
      }}
      handleModalSubmit={() => {
        dispatch({ type: 'setStopPocModalData', payload: {} });
        stopPoc(pocId, () => {
          getPocsTableData(pocsFilterItems, dispatch);
        });
      }}
    >
      <S.PopupContainer>
        <span>
          You are about to stop this POC, which means that all subscriptions for the associated
          account will be deleted!
        </span>
      </S.PopupContainer>
    </ConfirmationDialog>
  );
};

export default StopPocPopup;
