import ActionsCell from '../../../components/Cells/ActionsCell';
import EditableCell from '../../../components/Cells/EditableCell';
import EditableSelectCell from '../../../components/Cells/EditableSelectCell';

export interface TableColumn {
  initialState?: object;
  header?: string;
  accessor: string;
  style?: React.CSSProperties;
  disableSortBy?: boolean;
  Cell?: typeof EditableCell | typeof ActionsCell | typeof EditableSelectCell;
  disableFilters?: boolean;
  Filter?: React.ReactNode;
  filter?: boolean | string;
  type?: 'number' | 'text' | 'password';
  hasTooltip?: boolean;
}

const useBdbsTableColumns = () => {
  const columns: TableColumn[] = [];

  const bdbName: TableColumn = {
    header: 'Database Name',
    accessor: 'bdbName',
    style: { width: '20%' },
    Cell: EditableCell,
    disableFilters: true,
    hasTooltip: true,
  };
  const sizeGb: TableColumn = {
    header: 'Memory limit (GB)',
    accessor: 'sizeGb',
    style: { width: '20%' },
    Cell: EditableCell,
    disableFilters: true,
    type: 'number',
  };
  const maxThroughput: TableColumn = {
    header: 'Throughput',
    accessor: 'maxThroughput',
    style: { width: '20%' },
    Cell: EditableCell,
    disableFilters: true,
    type: 'number',
  };
  const replication: TableColumn = {
    header: 'Replication',
    accessor: 'replication',
    style: { width: '20%' },
    Cell: EditableSelectCell,
    disableFilters: true,
  };
  const actions: TableColumn = {
    accessor: 'actions',
    style: { width: '13%' },
    Cell: ActionsCell,
    disableFilters: true,
    disableSortBy: true,
  };

  const columnData = [bdbName, sizeGb, maxThroughput, replication, actions];

  columnData.forEach((col: TableColumn) => {
    columns.push({
      header: col.accessor !== 'actions' ? col.header : '',
      style: col.style,
      accessor: col.accessor,
      disableSortBy: col.disableSortBy ?? false,
      Cell: col.Cell,
      disableFilters: col.disableFilters,
      Filter: col.Filter ?? false,
      filter: col.filter ?? undefined,
      type: col.type,
      hasTooltip: col.hasTooltip,
    });
  });

  return columns;
};

export default useBdbsTableColumns;
