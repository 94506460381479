import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '@redislabsdev/redislabs-ui-components';
import * as CS from '../../styles/common.style';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { TABLE_DEFAULT_SORT } from '../../constants/accountsMarketplacesPageConstants';
import { ACCOUNTS_MARKETPLACES_WRITE } from '../../constants/permissionsConstants';
import { getAccountsMarketplacesTableData, getAccountsMarketplacesConfiguration } from './AccountsMarketplaces.api';
import { getAccountsMarketplacesColumns } from './AccountsMarketplacesPage.utils';


const AccountsMarketplacePageTableSection: React.FC = () => {
    const dispatch = useDispatch();
    const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

    const canWriteAccountsMarketplaces = permissions.includes(ACCOUNTS_MARKETPLACES_WRITE);

    const accountsMarketplacesFilterItems = useSelector(
        (state: StoreInterface) => state.accountsMarketplacesPage.accountsMarketplacesFilterItems
    );
    const accountsMarketplacesTableData = useSelector(
        (state: StoreInterface) => state.accountsMarketplacesPage.accountsMarketplacesTableData
    );
    const loadingAccountsMarketplacesTableData = useSelector(
        (state: StoreInterface) =>
            state.accountsMarketplacesPage.loadingAccountsMarketplacesTableData
    );
    const accountsMarketplacesStatuses = useSelector(
        (state: StoreInterface) => state.accountsMarketplacesPage.accountsMarketplacesStatuses
    );

    useEffect(() => {
        const callFun = async () => {
            if (accountsMarketplacesStatuses.length <= 1) {
                await getAccountsMarketplacesConfiguration(dispatch);
            }
        };

        callFun();
    }, []); // eslint-disable-line

    const { pageSize, pageCount, pageIndex } = accountsMarketplacesFilterItems;

    const fetchData = useCallback(
        ({ pageIndex: internalPageIndex, pageSize: internalPageSize, sortBy: internalSortBy }) => {
            const { id, desc } = internalSortBy[0];
            getAccountsMarketplacesTableData(
                {
                    ...accountsMarketplacesFilterItems,
                    pageIndex: internalPageIndex || pageIndex,
                    pageSize: internalPageSize || pageSize,
                    offset: internalPageIndex * internalPageSize,
                    sortBy: id,
                    sortDirection: desc ? 'desc' : 'asc',
                },
                dispatch
            );
        },
        [pageSize, pageIndex, accountsMarketplacesFilterItems] // eslint-disable-line
    );

    const controlledPaginationProps = useMemo(() => {
        return {
            loading: loadingAccountsMarketplacesTableData,
            pageCount,
            fetchData,
            pageIndex,
        };
    }, [pageIndex, pageCount, loadingAccountsMarketplacesTableData, fetchData]);

    return (
        <CS.TableWrapper>
            <Table
                columns={getAccountsMarketplacesColumns(
                    canWriteAccountsMarketplaces,
                    accountsMarketplacesStatuses,
                    dispatch
                )}
                data={accountsMarketplacesTableData}
                manualSortBy
                skipReset
                initialPageSize={pageSize}
                initialSortBy={TABLE_DEFAULT_SORT}
                controlledPaginationProps={controlledPaginationProps}
            />
        </CS.TableWrapper>
    );
};

export default AccountsMarketplacePageTableSection;
