import { string, number, boolean } from 'yup';
import { IManualAaBdb } from '../ManualAa.types';

export const newBdbId = -1;

export const initialBdb: IManualAaBdb = {
  bdbName: '',
  sizeGb: 1,
  maxThroughput: 25000,
  replication: true,
  bdbId: newBdbId,
};

export const nameSchema = () => {
  const regex = /^[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9]$|^[a-zA-Z]$/;
  const maxLength = 40;
  const stringFormatMsg = `Database name must be up to ${maxLength} characters long, include only letters, digits, or hyphen ('-'), start with a letter, and end with a letter or digit.`;
  return string()
    .required('Database name is required')
    .max(maxLength, stringFormatMsg)
    .matches(regex, stringFormatMsg);
};

export const sizeSchema = () => {
  const min = 0.1;
  const max = 96000;
  const errorMsg = `Must be between ${min} and ${max}`;
  return number()
    .typeError('Memory limit must be a number')
    .required('Memort limit is required')
    .min(min, errorMsg)
    .max(max, errorMsg);
};

export const throughputSchema = () => {
  const min = 1000;
  const max = 10000000;
  const errorMsg = `Must be between ${min} to ${max}`;
  return number()
    .typeError('Throughput must be a number')
    .required('Throughput is required')
    .max(max, errorMsg)
    .min(min, errorMsg);
};

export const replicationSchema = () => {
  return boolean().required('Replication is required');
};
