interface RootPageDefaultStateInterface {
  isLoggedIn: boolean;
  loggedUser: any; // eslint-disable-line
  permissions: string[];
  rootPageLoading: boolean;
}

export const rootPageDefaultState: RootPageDefaultStateInterface = {
  isLoggedIn: false,
  loggedUser: null,
  permissions: [],
  rootPageLoading: true,
};

export default function rootPageReducer(state, action) {
  const { rootPage } = state;

  switch (action.type) {
    case 'setRootLoading':
      return {
        ...state,
        rootPage: {
          ...rootPage,
          rootPageLoading: action.payload,
        },
      };

    case 'setUserName':
      return {
        ...state,
        rootPage: {
          ...rootPage,
          isLoggedIn: true,
          loggedUser: action.payload,
        },
      };

    case 'setLogOutState':
      return {
        ...state,
        rootPage: {
          ...rootPage,
          isLoggedIn: false,
        },
      };

    case 'setPermissions':
      return {
        ...state,
        rootPage: {
          ...rootPage,
          rootPageLoading: false,
          permissions: action.payload,
        },
      };

    default:
      return state;
  }
}
