import { api, buildUrl } from '../../api/config';
import { displayErrors } from '../rootPage/RootPage.utils';
import { prepareFilterTableRequestData } from '../../utils/filterTable.utils';
import { showToast } from '../../components/Toast/Toast';

const getCouponsTableDataApiRequest = (dataToSend) =>
  api.get(buildUrl('coupon'), {
    params: dataToSend,
  });

export async function getCouponsTableData(reqParams, dispatch) {
  dispatch({ type: 'setLoadingCouponsTableData', payload: true });
  dispatch({
    type: 'setCouponsFilterItems',
    payload: {
      pageIndex: reqParams.pageIndex,
      pageSize: reqParams.pageSize,
      offset: reqParams.offset,
      sortBy: reqParams.sortBy,
      sortDirection: reqParams.sortDirection,
    },
  });

  const newParams = {
    ...reqParams,
    status: reqParams.status.code,
  };

  getCouponsTableDataApiRequest(
    prepareFilterTableRequestData(newParams, ['pageCount', 'pageIndex'])
  )
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({
          type: 'setCouponsFilterItems',
          payload: {
            pageCount: Math.ceil(data.itemsTotal / reqParams.pageSize),
          },
        });

        dispatch({
          type: 'setCouponsTableData',
          payload: data.items,
        });
      }
    })
    .finally(() => {
      dispatch({ type: 'setLoadingCouponsTableData', payload: false });
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to get coupons');
    });
}

const createNewCouponRequest = (dataToSend) => api.post(buildUrl('coupon'), dataToSend);

export async function createNewCoupon(reqParams, onSuccessCallback) {
  createNewCouponRequest(prepareFilterTableRequestData(reqParams))
    .then(({ status }) => {
      if (status === 200) {
        showToast('The coupon was created successfully', 'success');
        onSuccessCallback();
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to create a new coupon');
    });
}
const extendCouponRequest = (couponId, dataToSend) =>
  api.put(`${buildUrl('coupon')}/${couponId}/extend`, dataToSend);

export async function extendCoupon(couponId, reqParams, onSuccessCallback) {
  extendCouponRequest(couponId, prepareFilterTableRequestData(reqParams))
    .then(({ status }) => {
      if (status === 200) {
        showToast('The coupon was extended successfully', 'success');
        onSuccessCallback();
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to extend coupon');
    });
}

const couponConfigRequest = () => api.get(`${buildUrl('bousers')}/configuration/coupons`);

export async function getCouponsConfiguration(dispatch) {
  couponConfigRequest()
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({ type: 'setCouponConfiguration', payload: data });
        dispatch({ type: 'setLoadCouponConfig', payload: false });
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to get Coupons configuration');
    });
  return null;
}

const couponEnableRequest = (couponId, enable) =>
  api.put(`${buildUrl('coupon')}/${couponId}/status`, { enable });

export async function enableCoupon(couponId, enable, callback) {
  couponEnableRequest(couponId, enable)
    .then(({ status }) => {
      if (status === 200) {
        showToast(`The coupon was ${enable ? 'enabled' : 'disabled'} successfully`, 'success');
        callback();
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed trying to ${enable ? 'enable' : 'disable'} coupon`);
    });
}

export const exportCouponUsage = async (couponId: number) => {
  const { data } = await api.get<Blob>(`${buildUrl('coupon')}/${couponId}/export`, {
    headers: {
      'Content-Type': 'text/csv; charset=UTF-8',
      'Content-Disposition': 'attachment;filename=coupon_accounts.csv',
    },
    responseType: 'blob'
  });

  const blob = new Blob([data], { type: 'text/csv' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = 'coupon_accounts.csv';
  link.click();
};
