import React, { useState } from 'react';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { Input } from '@redislabsdev/redis-ui-components';
import { CalendarIcon, CancelIcon } from '@redislabsdev/redis-ui-icons';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import * as CS from '../../../styles/common.style';

import { MAX_EXTENSION } from '../PocsPage.utils';
import { updateStateOnInputType, utcDateStartOfDay } from '../../../utils/common.utils';
import { errorsMessages } from '../../../utils/errorMessages';
import { createNewPoc, getPocAccountName, getPocsTableData } from '../PocsPage.api';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import * as S from './NewPocPopup.style';

const NewPocPopup: React.FC = () => {
  const dispatch = useDispatch();

  const newPocPopupData = useSelector((state: StoreInterface) => state.pocsPage.newPocPopupData);
  const pocsFilterItems = useSelector((state: StoreInterface) => state.pocsPage.pocsFilterItems);
  const showCreateNewPocModal = useSelector(
    (state: StoreInterface) => state.pocsPage.showCreateNewPocModal
  );
  const isNewPocEmailListValid = useSelector(
    (state: StoreInterface) => state.pocsPage.newPocValidation.isNewPocEmailListValid
  );

  const { accountNumber, comments, pocEndDate, newPocEmailList } = newPocPopupData;

  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const [currentEndMonth, setCurrentEndMonth] = useState<Moment>(utcDateStartOfDay);
  const [accountName, setAccountName] = useState('');

  // TODO: set a flag, block submit and display a loader when the name request is triggered
  const callGetPocAccountName = (value: string) =>
    getPocAccountName(parseInt(value, 10), (accName) => setAccountName(accName));

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setNewPocPopupData',
      dispatch,
    });

  return (
    <ConfirmationDialog
      title="New POC"
      isOpen={showCreateNewPocModal}
      cancelButtonLabel="Cancel"
      submitButtonLabel="Save"
      handleModalCancel={() => {
        dispatch({ type: 'setShowCreateNewPocModal', payload: false });
        dispatch({ type: 'resetNewPocValidation' });
        setCurrentEndMonth(moment(utcDateStartOfDay));
        setAccountName('');
      }}
      handleModalSubmit={() => {
        createNewPoc(
          {
            accountNumber,
            utcDateStartOfDay,
            pocEndDate,
            comments,
            newPocEmailList,
          },
          () => {
            dispatch({ type: 'setShowCreateNewPocModal', payload: false });
            dispatch({ type: 'resetNewPocValidation' });
            setCurrentEndMonth(moment(utcDateStartOfDay));
            getPocsTableData(pocsFilterItems, dispatch);
            setAccountName('');
          }
        );
      }}
      submitDisabled={!isNewPocEmailListValid || !accountNumber || !pocEndDate}
    >
      <S.NewPocPopupContainer>
        <S.NewPocPopupRow>
          <label htmlFor="accountNumber">
            Account Number *
            <Input
              autoComplete="new-account-number"
              data-testid="textinput--account-number"
              width="100%"
              type="number"
              name="accountNumber"
              id="accountNumber"
              value={accountNumber}
              onBlur={(event) => {
                const { value } = event.target;
                value && callGetPocAccountName(value);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // @ts-ignore
                  const { value } = event.target;
                  value && callGetPocAccountName(value);
                }
              }}
              onChange={(event) => updateStateOnInputTypeWrapper(event)}
            />
          </label>

          <label htmlFor="accountName">
            Account Name
            <CS.ViewValues displayBlock data-testid="textinput--new-poc-account-name">
              {accountName || '--'}
            </CS.ViewValues>
          </label>
        </S.NewPocPopupRow>
        <S.NewPocPopupRow>
          <label htmlFor="startPocDate">
            POC Start Date
            <CS.ViewValues displayBlock data-testid="textinput--new-poc-start-date">
              {utcDateStartOfDay?.format('DD/MM/YYYY')}
            </CS.ViewValues>
          </label>

          <label htmlFor="pocEndDate">
            POC End Date *
            <CS.SpanWithDate>
              <span>
                {pocEndDate?.format('DD/MM/YYYY')}
              </span>
              <CS.SpanWithDateButtonsWrapper>
                {pocEndDate ? (
                  <Button
                    data-testid="button--clear-poc-end-date"
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      dispatch({
                        type: 'setNewPocPopupData',
                        payload: { pocEndDate: null },
                      });
                    }}
                  >
                    <CancelIcon size="L" />
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    size="small"
                    data-testid="button--poc-end-date"
                    onClick={() => {
                      setShowEndDatePicker(!showEndDatePicker);
                    }}
                  >
                    <CalendarIcon size="L" />
                  </Button>
                )}
              </CS.SpanWithDateButtonsWrapper>
            </CS.SpanWithDate>
          </label>
        </S.NewPocPopupRow>
        <S.NewPocPopupRow>
          <label htmlFor="newPocEmailList">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            Email List
            <Input
              autoComplete="new-new-poc-email-list"
              id="newPocEmailList"
              name="newPocEmailList"
              data-testid="textinput--new-poc-email-list"
              type="email"
              value={newPocEmailList}
              error={!isNewPocEmailListValid ? errorsMessages.newPocEmailList : undefined}
              onBlur={({ target }) => {
                dispatch({
                  type: 'setNewPocValidation',
                  payload: { isNewPocEmailListValid: target.validity.valid },
                });
              }}
              width="100%"
              multiple
              pattern="([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)*(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*"
              onChange={(event) => updateStateOnInputTypeWrapper(event)}
            />
          </label>
        </S.NewPocPopupRow>
        <S.NewPocPopupRow>
          <label htmlFor="comments">
            POC Reason
            <CS.Textarea
              name="comments"
              id="comments"
              data-testid="textarea--comments"
              rows={6}
              value={comments} // protection for null values
              onChange={(event) => updateStateOnInputTypeWrapper(event)}
            />
          </label>
        </S.NewPocPopupRow>
      </S.NewPocPopupContainer>
      {showEndDatePicker ? (
        <S.DatePickerAligner>
          <CS.DatePickerWrapper
            useAsRange
            disabledDays={{
              before: utcDateStartOfDay.toDate(),
              after: moment(utcDateStartOfDay).add(MAX_EXTENSION.rule2, 'days').toDate(),
            }}
            showOnClickOutsideFn={() => {
              setShowEndDatePicker(false);
            }}
            currentMonth={currentEndMonth.toDate()}
            setCurrentMonth={(newMonth, keepPickerOpen) => {
              setCurrentEndMonth(moment(newMonth));
              setShowEndDatePicker(keepPickerOpen);
            }}
            currentDateAsObject={{
              from: utcDateStartOfDay.toDate(),
              to: pocEndDate?.toDate() || utcDateStartOfDay.toDate(),
            }}
            setCurrentDay={({ to }) => {
              dispatch({
                type: 'setNewPocPopupData',
                payload: {
                  pocEndDate: moment(to),
                },
              });
              setShowEndDatePicker(false);
            }}
          />
        </S.DatePickerAligner>
      ) : null}
    </ConfirmationDialog>
  );
};

export default NewPocPopup;
