import { QuantityCounter, ToggleButton, Tooltip } from '@redislabsdev/redislabs-ui-components';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '@redislabsdev/redis-ui-styles';
import { InfoIcon } from '@redislabsdev/redis-ui-icons';
import { couponPopUpLabels } from '../../../constants/couponsPageConstants';
import { errorsMessages } from '../../../utils/errorMessages';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import * as S from './CouponPopup.style';

interface CouponPhase2Props {
  onStateChange: (event) => void;
}

export function CouponPhase2({ onStateChange }: CouponPhase2Props) {
  const dispatch = useDispatch();
  const {
    couponPopupData,
    couponsPhase2Enabled,
    couponCustomerRetentionEnabled,
    couponCreditsExpirationPeriodMandatory,
    newCouponValidation,
  } = useSelector((state: StoreInterface) => state.couponsPage);

  const { isCreditExpirationValid } = newCouponValidation;

  const { couponId, creditExpirationPeriod, addable, forCustomerRetention } = couponPopupData;

  /**
   * A credit expiration value is mandatory if *isCouponCreditsExpirationPeriodMandatory* is true.
   * A credit expiration value is valid if it's an integer between 1 and 36.
   * If *isCouponCreditsExpirationPeriodMandatory* is false, then an empty string is valid too.
   */
  const handleCreditExpirationChange = useCallback(
    (months: number | null) => {
      dispatch({
        type: 'setCouponPopupData',
        payload: { creditExpirationPeriod: months },
      });

      let isValid = false;
      if (months) {
        isValid = Number.isInteger(months) && months >= 1 && months <= 36;
      }

      let payload;
      if (couponCreditsExpirationPeriodMandatory && (!months || !isValid)) {
        payload = false;
      } else {
        payload = months ? isValid : true;
      }

      dispatch({
        type: 'setNewCouponValidation',
        payload: {
          isCreditExpirationValid: payload,
        },
      });
    },
    [dispatch, couponCreditsExpirationPeriodMandatory]
  );

  if (couponId) {
    return null;
  }

  const CreditExpirationPeriod = (
    <S.CreditExpirationPeriod>
      <label htmlFor="creditExpirationPeriod">
        {couponPopUpLabels.creditExpirationPeriod}{' '}
        <S.OptionalSpan>
          ({couponPopUpLabels.inMonths}){couponCreditsExpirationPeriodMandatory ? '*' : ''}
        </S.OptionalSpan>
      </label>
      <QuantityCounter
        name="creditExpirationPeriod"
        value={creditExpirationPeriod || (couponCreditsExpirationPeriodMandatory ? 1 : undefined)}
        onChange={handleCreditExpirationChange}
        stepSize={1}
        width={couponCustomerRetentionEnabled ? '192px' : '174px'}
        inputTestId="credit-expiration-input"
        minValue={1}
        maxValue={36}
        toFixedLength={0}
        error={!isCreditExpirationValid ? errorsMessages.creditExpirationPeriod : undefined}
      />
    </S.CreditExpirationPeriod>
  );

  const AddableCoupon = (
    <S.AddableCouponContainer>
      <ToggleButton
        checked={addable}
        name="addable"
        onChange={onStateChange}
        id="addable-checkbox"
        data-testid="toggle-button--addable"
      />
      <span>{couponPopUpLabels.addable}</span>
      <Tooltip
        tooltipContent={couponPopUpLabels.addableTooltip}
        placement="top"
        trigger="hover"
        textColor={theme.semantic.color.text.neutral700}
      >
        <InfoIcon data-testid="icon-info" size="L" color="neutral700" />
      </Tooltip>
    </S.AddableCouponContainer>
  );

  const CustomerRetention = (
    <S.AddableCouponContainer>
      <ToggleButton
        checked={forCustomerRetention}
        name="forCustomerRetention"
        onChange={onStateChange}
        id="customer-retention-checkbox"
        data-testid="toggle-button--customer-retention"
      />
      <span>{couponPopUpLabels.customerRetention}</span>
    </S.AddableCouponContainer>
  );

  if (couponsPhase2Enabled && !couponCustomerRetentionEnabled) {
    return (
      <S.CouponPopupRow>
        {CreditExpirationPeriod}
        {AddableCoupon}
      </S.CouponPopupRow>
    );
  }

  if (couponsPhase2Enabled && couponCustomerRetentionEnabled) {
    return (
      <div>
        <S.CouponPopupRow>{CreditExpirationPeriod}</S.CouponPopupRow>
        <S.CouponPopupRow>
          {CustomerRetention}
          {AddableCoupon}
        </S.CouponPopupRow>
      </div>
    );
  }

  return null;
}
