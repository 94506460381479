import React from 'react';
import { SideBar } from '@redislabsdev/redislabs-ui-components';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { SideBarLinkProps } from './MainSideBar.types';

const SideBarLink = ({ title, icon, path, activeOnPaths, type }: SideBarLinkProps) => {
  const match = useRouteMatch({ path: activeOnPaths || path });

  if (type === 'anchor') {
    return (
        <a href={path} title={title} target="_blank" rel="noopener noreferrer">
            <SideBar.Item tooltipProps={{ tooltipContent: title }}>
                <SideBar.Item.Icon name={icon} />
                <SideBar.Item.Text>
                    {title}
                </SideBar.Item.Text>
            </SideBar.Item>
        </a>
    );
  }

  const isActive = !!match;

  return (
      <NavLink title={title} to={path as string}>
          <SideBar.Item isActive={isActive} tooltipProps={{ tooltipContent: title }}>
              <SideBar.Item.Icon name={icon} />
              <SideBar.Item.Text>
                  {title}
              </SideBar.Item.Text>
          </SideBar.Item>
      </NavLink>
  );
};

export default SideBarLink;
