import {
  MarketplaceContractsFilterInterface,
  MarketplaceContractsPageFilterInterface,
  MarketplaceContractsTableFilterInterface,
} from '../interfaces/marketplaceContractsStore.type';

const marketplaceContractsPageFilterItemsDefaultValues: MarketplaceContractsPageFilterInterface = {
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  smAccountId: '',
  smAccountName: '',
  contractId: '',
  customerName: '',
  status: {
    code: '',
    label: 'All',
  },
};

const marketplaceContractsTableFilterItemsDefaultValues: MarketplaceContractsTableFilterInterface =
{
  pageSize: 10,
  sortDirection: 'desc',
  offset: 0,
  pageIndex: 0,
  pageCount: 0,
  sortBy: '',
};

const marketplaceContractsFilterItemsDefaultValues: MarketplaceContractsFilterInterface = {
  ...marketplaceContractsPageFilterItemsDefaultValues,
  ...marketplaceContractsTableFilterItemsDefaultValues,
};

export const marketplaceContractsDefaultState = {
  marketplaceContractsFilterItems: marketplaceContractsFilterItemsDefaultValues,
  marketplaceContractsTableData: [],
  loadingMarketplaceContractsTableData: false,
  marketplaceContractsStatuses: [{ code: '', label: 'All' }],
  marketplaceContractsLoadPocConfig: true,
};

export default function marketplaceContractsPageReducer(state, action) {
  const { marketplaceContracts } = state;

  switch (action.type) {
    case 'setLoadingMarketplaceContractsTableData':
      return {
        ...state,
        marketplaceContracts: {
          ...marketplaceContracts,
          loadingMarketplaceContractsTableData: action.payload,
        },
      };

    case 'setMarketplaceContractsTableData': {
      return {
        ...state,
        marketplaceContracts: {
          ...marketplaceContracts,
          marketplaceContractsTableData: action.payload,
        },
      };
    }

    case 'setMarketplaceContractsFilterItems': {
      const { marketplaceContractsFilterItems } = marketplaceContracts;

      return {
        ...state,
        marketplaceContracts: {
          ...marketplaceContracts,
          marketplaceContractsFilterItems: {
            ...marketplaceContractsFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'resetMarketplaceContractsPageFilterItems': {
      const { marketplaceContractsFilterItems } = marketplaceContracts;

      return {
        ...state,
        marketplaceContracts: {
          ...marketplaceContracts,
          marketplaceContractsFilterItems: {
            ...marketplaceContractsFilterItems,
            ...marketplaceContractsPageFilterItemsDefaultValues,
          },
        },
      };
    }

    case 'clearMarketplaceContractsIndividualDate': {
      const { marketplaceContractsFilterItems } = marketplaceContracts;

      return {
        ...state,
        marketplaceContracts: {
          ...marketplaceContracts,
          marketplaceContractsFilterItems: {
            ...marketplaceContractsFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'setMarketplaceContractsLoadPocConfig': {
      return {
        ...state,
        marketplaceContracts: {
          ...marketplaceContracts,
          marketplaceContractsLoadPocConfig: action.payload,
        },
      };
    }

    case 'setMarketplaceContractsStatuses': {
      const { marketplaceContractsStatuses } = marketplaceContracts;

      return {
        ...state,
        marketplaceContracts: {
          ...marketplaceContracts,
          marketplaceContractsStatuses: [...marketplaceContractsStatuses, ...action.payload],
        },
      };
    }

    default:
      return state;
  }
}
