import * as R from 'ramda';

export function formatCountriesAndStates(elements) {
  if (!elements) return [];

  return elements.map((el) => ({
    id: el.code,
    title: el.name,
  }));
}

export function selectStatesFromCountry(id, countriesStates) {
  return formatCountriesAndStates(R.find(R.propEq('code', id))(countriesStates)?.states);
}
