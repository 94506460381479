import moment from 'moment';
import * as R from 'ramda';

import { api, buildUrl } from '../../../api/config';
import { displayErrors } from '../../rootPage/RootPage.utils';
import { Account } from '../../../interfaces/marketplaceContractsStore.type';
import { showToast } from '../../../components/Toast/Toast';

const getOneMarketplaceContractApiRequest = (id) =>
    api.get(`${buildUrl('contracts')}/marketplace/${id}`);

export async function getOneMarketplaceContract(id, dispatch) {
  getOneMarketplaceContractApiRequest(id)
    .then(({ data, status }) => {
      if (status === 200) {
          const newAccounts = data.accounts.map(mapAccount);
        const dataToSend = {
          ...data,
          startDate: moment.utc(data.startDate).startOf('day'),
          endDate: moment.utc(data.endDate).startOf('day'),
          onDemandChargeStart: data?.onDemandChargeStart
            ? moment.utc(data.onDemandChargeStart).startOf('day')
            : null,
          redislabsEmailList: R.join(', ', data.redislabsEmailList),
          accounts: newAccounts
        };
        dispatch({ type: 'setMarketplaceContractInfo', payload: dataToSend });
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed trying to get id ${id} Marketplace contract details`);
    })
    .finally(() => dispatch({ type: 'setLoadingMarketplaceContract', payload: false }));
}


export async function updateMarketplaceContractAccounts(contractId, dispatch) {
    getOneMarketplaceContractApiRequest(contractId)
        .then(({ data, status }) => {
            if (status === 200) {
                const newAccounts = data.accounts.map(mapAccount);
                dispatch({ type: 'setMarketplaceContractAccounts', payload: newAccounts });
            }
        })
        .catch((err) => {
            displayErrors(err, `Failed trying update accounts of Marketplace contract ${contractId}.`);
        });
}


const saveMarketplaceContractRequest = (contractId, reqParams) =>
  api.put(`${buildUrl('contracts')}/marketplace/${contractId}`, reqParams);

export async function saveMarketplaceContract({ contractId, reqParams, callBack, dispatch }) {
  const { redislabsEmailList, accounts } = reqParams;
  const newParams = {
    ...reqParams,
    redislabsEmailList: R.isEmpty(redislabsEmailList)
      ? []
      : R.map(R.trim, R.split(',', reqParams?.redislabsEmailList)),
    accounts : accounts ? accounts.map((acc) =>  {return { accountId: acc.accountId };}) : null
  };

  saveMarketplaceContractRequest(contractId, newParams)
    .then(({ status }) => {
      if (status === 200) {
        callBack?.();
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed trying save Marketplace contract id ${contractId} details`);
    })
    .finally(() => dispatch({ type: 'setLoadingMarketplaceContract', payload: false }));
}

const requestGetAccDetails = (accId) =>
  api.get(`${buildUrl('accounts')}/${accId}`);

export async function addAccountWithDetails(accId, dispatch) {
  requestGetAccDetails(accId)
      .then(({ data, status }) => {
        if (status === 200) {
          if(data.state === 'enabled') {
              dispatch({
                  type: 'addMarketplaceContractAccountOnEdit',
                  payload:
                      {
                          accountId: `${accId}`,
                          accountName: `${data.accountName}`
                      },
              });
              dispatch( { type:'setMarketplaceContractShowAddAccountRow', payload: false });
              // used to set the form's touch state
              dispatch( { type:'setMarketplaceFormTouchedState', payload: true });
          } else {
              showToast(`Account ${accId} is not enabled` );
          }
        }
      })
      .catch((err) => {
        displayErrors(err, "Failed trying to get account info's");
      });
}

const mapAccount = (acc):Account => ({
    accountId: acc.accountId,
    accountName: acc.accountName,
    status: acc.accountMarketplaceStatus.toLowerCase()
});
