import React from 'react';
import { Input } from '@redislabsdev/redislabs-ui-components';
import * as S from '../../contractsPage/contractPage/infoSection/infoSection.style';

const McpAddAccount = (props) => {
    const {
        onAddAccount
    } = props;

    return (
      <S.SpanDisplayFlexInline>
        <S.SMAccountsNewLine>
          <Input
            autoComplete="new-accountId"
            id='tempId'
            name='tempId'
            data-testid="textinput--accountId"
            type='number'
            defaultValue='tempId'
            onBlur={(event) => {
                        const { value } = event.target;

                        value && onAddAccount(value);
                    }}
            onKeyPress={(event) => {
                        const { value } = event.target as HTMLInputElement;
                        if (event.key === 'Enter' && value) {
                            onAddAccount(value);
                        }
                    }}
          />
        </S.SMAccountsNewLine>
      </S.SpanDisplayFlexInline>
    );

};

export default McpAddAccount;
