import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from '@redislabsdev/redislabs-ui-components';

import * as CS from '../../styles/common.style';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { columnNames, getTableData, TABLE_DEFAULT_SORT } from './contractsPage.utils';


const ContractsPageTableSection: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const filterItems = useSelector((state: StoreInterface) => state.contractsPage.filterItems);

  const contractsTableData = useSelector(
    (state: StoreInterface) => state.contractsPage.contractsTableData
  );
  const loadingContractsTableData = useSelector(
    (state: StoreInterface) => state.contractsPage.loadingContractsTableData
  );

  const { pageSize, pageCount, pageIndex } = filterItems;

  const fetchData = useCallback(
    ({ pageIndex: internalPageIndex, pageSize: internalPageSize, sortBy: internalSortBy }) => {
      const { id, desc } = internalSortBy[0];

      getTableData(
        {
          ...filterItems,
          pageIndex: internalPageIndex || pageIndex,
          pageSize: internalPageSize || pageSize,
          offset: internalPageIndex * internalPageSize,
          sortBy: id,
          sortDirection: desc ? 'desc' : 'asc',
        },
        dispatch
      );
    },
    [pageSize, pageIndex, filterItems] // eslint-disable-line
  );

  const controlledPaginationProps = useMemo(() => {
    return {
      loading: loadingContractsTableData,
      pageCount,
      fetchData,
      pageIndex,
    };
  }, [pageIndex, pageCount, loadingContractsTableData, fetchData]);

  return (
      <CS.TableWrapper>
          <Table
              columns={columnNames}
              data={contractsTableData}
              manualSortBy
              skipReset
              initialPageSize={pageSize}
              initialSortBy={TABLE_DEFAULT_SORT}
              controlledPaginationProps={controlledPaginationProps}
              onRowClick={(props, event) => {
          // @ts-ignore
          const { tagName }: string = event.target;
          const {
            values: { contractId: rowContractId },
          } = props;

          if (tagName !== 'svg' && tagName !== 'circle') {
            history.push(`/contracts/${rowContractId}/view`);
          }
        }}
      />
      </CS.TableWrapper>
  );
};

export default ContractsPageTableSection;
