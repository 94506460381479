import React from 'react';

const SubscriptionsPage: React.FC = () => {
  return (
      <>
          <div className="content-header">
              Subscriptions Page
          </div>
      </>
  );
};

export default SubscriptionsPage;
