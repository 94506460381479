import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from '@redislabsdev/redislabs-ui-components';
import {
  Input,
  RangeDatePicker,
  DateRange,
  IconButton,
  Popover,
} from '@redislabsdev/redis-ui-components';
import { CancelIcon, CalendarIcon, ExportIcon } from '@redislabsdev/redis-ui-icons';

import * as CS from '../../styles/common.style';
import { displayFormattedDate, handleDayClick } from '../contractsPage/contractsPage.utils';
import { POCS_WRITE } from '../../constants/permissionsConstants';
import { updateStateOnInputType } from '../../utils/common.utils';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import ClearAllAndApplyButtons from '../contractsPage/ClearAllAndApplyButtons';
import NewPocPopup from './newPoc/NewPocPopup';
import * as S from './PocsPage.styles';
import { exportPocTable, getPocsTableData, getPocStatuses } from './PocsPage.api';

import { PocsColumnNames } from './PocsPage.utils';
import ExtendPocPopup from './extendPoc/ExtendPocPopup';
import StopPocPopup from './stopPoc/StopPocPopup';

const PocsPage: React.FC = () => {
  const dispatch = useDispatch();

  const pocsFilterItems = useSelector((state: StoreInterface) => state.pocsPage.pocsFilterItems);
  const pocStatuses = useSelector((state: StoreInterface) => state.pocsPage.pocStatuses);

  const pocsTableData = useSelector((state: StoreInterface) => state.pocsPage.pocsTableData);
  const loadingPocsTableData = useSelector(
    (state: StoreInterface) => state.pocsPage.loadingPocsTableData
  );

  const loadPocConfig = useSelector((state: StoreInterface) => state.pocsPage.loadPocConfig);
  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

  const {
    pocId,
    smAccountId,
    smAccountName,
    boUserName,
    startDateFrom,
    startDateTo,
    endDateFrom,
    endDateTo,
    status,
  } = pocsFilterItems;

  const [startDateRange, setStartDateRange] = useState<DateRange>();
  const [endDateRange, setEndDateRange] = useState<DateRange>();

  const canCreatePocs = permissions.includes(POCS_WRITE);

  useEffect(() => {
    const callFun = async () => {
      if (pocStatuses.length <= 1) {
        await getPocStatuses(dispatch);
      }
    };

    callFun();
  }, []); // eslint-disable-line

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setPocsFilterItems',
      dispatch,
    });

  return (
    <>
      <CS.PageTitleAndActions>
        <CS.JustifyToLeft>
          <CS.PageHeaderTitle>POC Report</CS.PageHeaderTitle>
          <S.PocsPageWrapper />
        </CS.JustifyToLeft>
        <CS.JustifyToRight>
          {canCreatePocs && (
            <Button
              data-testid="button--new-contract"
              onClick={() => dispatch({ type: 'setShowCreateNewPocModal', payload: true })}
            >
              New POC
            </Button>
          )}
        </CS.JustifyToRight>
      </CS.PageTitleAndActions>
      <CS.FilterSectionWrapper>
        <CS.FilterSection singleRow={false}>
          <div>
            <CS.FilterRow>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="startDate">
                <span>Start date</span>
                <CS.SpanWithDate useAsRange>
                  <span>
                    {`${displayFormattedDate(startDateFrom)}
							 			- ${displayFormattedDate(startDateTo)}`}
                  </span>
                  <CS.SpanWithDateButtonsWrapper>
                    {startDateFrom || startDateTo ? (
                      <Button
                        data-testid="button--clear-start-date"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          dispatch({
                            type: 'setPocsFilterItems',
                            payload: {
                              startDateFrom: null,
                              startDateTo: null,
                            },
                          });
                          setStartDateRange(undefined);
                        }}
                      >
                        <CancelIcon size="L" />
                      </Button>
                    ) : (
                      <Popover.Compose>
                        <Popover.Trigger>
                          <IconButton data-testid="button--start-date" icon={CalendarIcon} />
                        </Popover.Trigger>
                        <CS.DualDatePickerPopover placement="right">
                          <CS.DualDatePickerPopoverBody>
                            <RangeDatePicker
                              selectedRange={startDateRange}
                              onRangeSelect={(selectedRange) => {
                                setStartDateRange(selectedRange);
                                handleDayClick({
                                  dispatch,
                                  newDate: selectedRange,
                                  key: 'startDate',
                                  dispatchType: 'setPocsFilterItems',
                                });
                              }}
                            />
                          </CS.DualDatePickerPopoverBody>
                        </CS.DualDatePickerPopover>
                      </Popover.Compose>
                    )}
                  </CS.SpanWithDateButtonsWrapper>
                </CS.SpanWithDate>
              </label>
              <label htmlFor="pocId">
                POC ID
                <Input
                  data-testid="textinput--poc-id"
                  width="16rem"
                  placeholder="All"
                  type="number"
                  name="pocId"
                  id="pocId"
                  value={pocId}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
              <label htmlFor="boUserName">
                Activated by
                <Input
                  autoComplete="new-activated-by"
                  data-testid="textinput--activated-by"
                  width="16rem"
                  placeholder="All"
                  type="text"
                  name="boUserName"
                  id="boUserName"
                  value={boUserName}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
            </CS.FilterRow>
            <CS.FilterRow>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="endDate">
                <span>End date</span>
                <CS.SpanWithDate useAsRange>
                  <span>
                    {`${displayFormattedDate(endDateFrom)}
									- ${displayFormattedDate(endDateTo)}`}
                  </span>
                  <CS.SpanWithDateButtonsWrapper>
                    {endDateFrom || endDateTo ? (
                      <Button
                        data-testid="button--clear-end-date"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          dispatch({
                            type: 'setPocsFilterItems',
                            payload: {
                              endDateFrom: null,
                              endDateTo: null,
                            },
                          });
                          setEndDateRange(undefined);
                        }}
                      >
                        <CancelIcon size="L" />
                      </Button>
                    ) : (
                      <Popover.Compose>
                        <Popover.Trigger>
                          <IconButton data-testid="button--end-date" icon={CalendarIcon} />
                        </Popover.Trigger>
                        <CS.DualDatePickerPopover placement="right">
                          <CS.DualDatePickerPopoverBody>
                            <RangeDatePicker
                              selectedRange={endDateRange}
                              onRangeSelect={(selectedRange) => {
                                setEndDateRange(selectedRange);
                                handleDayClick({
                                  dispatch,
                                  newDate: selectedRange,
                                  key: 'endDate',
                                  dispatchType: 'setPocsFilterItems',
                                });
                              }}
                            />
                          </CS.DualDatePickerPopoverBody>
                        </CS.DualDatePickerPopover>
                      </Popover.Compose>
                    )}
                  </CS.SpanWithDateButtonsWrapper>
                </CS.SpanWithDate>
              </label>
              <label htmlFor="smAccountId">
                SM Account ID
                <Input
                  autoComplete="new-sm-account-number"
                  data-testid="textinput--sm-account-number"
                  width="16rem"
                  placeholder="All"
                  type="number"
                  name="smAccountId"
                  id="smAccountId"
                  value={smAccountId}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
              <label htmlFor="smAccountName">
                SM Account Name
                <Input
                  autoComplete="new-sm-account-name"
                  data-testid="textinput--sm-account-name"
                  width="16rem"
                  placeholder="All"
                  type="text"
                  name="smAccountName"
                  id="smAccountName"
                  value={smAccountName}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
              <label htmlFor="status">
                {loadPocConfig ? (
                  <CS.StatusLoading>Loading...</CS.StatusLoading>
                ) : (
                  <>
                    Status
                    <S.StyledPocsDropdownMenu
                      dataTestIdSuffix="dropdown-status"
                      items={pocStatuses}
                      renderItem={(item) => <div>{item.label}</div>}
                      selectedItem={status}
                      onSelectedItemChange={(item) => {
                        dispatch({
                          type: 'setPocsFilterItems',
                          payload: { status: item.selectedItem },
                        });
                      }}
                    />
                  </>
                )}
              </label>
            </CS.FilterRow>
          </div>
          <ClearAllAndApplyButtons
            applyButtonCallback={() => getPocsTableData(pocsFilterItems, dispatch)}
            clearAllCallback={() =>
              dispatch({
                type: 'resetPocsFilterItems',
              })
            }
          />
        </CS.FilterSection>
      </CS.FilterSectionWrapper>
      <CS.TableActionsWrapper>
        <Button
          variant="link"
          data-testid="button--export-poc-entries"
          onClick={() => exportPocTable(pocsFilterItems)}
        >
          <ExportIcon size="L" />
          Export all
        </Button>
      </CS.TableActionsWrapper>
      <CS.TableWrapper>
        {loadingPocsTableData ? (
          <div>Loading...</div>
        ) : (
          <Table columns={PocsColumnNames} data={pocsTableData} />
        )}
      </CS.TableWrapper>
      <NewPocPopup />
      <ExtendPocPopup />
      <StopPocPopup />
    </>
  );
};
export default PocsPage;
