import { api, buildUrl } from '../../api/config';
import { displayErrors } from '../rootPage/RootPage.utils';
import { prepareFilterTableRequestData } from '../../utils/filterTable.utils';

const getMarketplaceContractsTableDataApiRequest = (dataToSend) =>
  api.get(`${buildUrl('contracts')}`, {
    params: dataToSend,
  });

export async function getMarketplaceContractsTableData(reqParams, dispatch) {
  dispatch({ type: 'setLoadingMarketplaceContractsTableData', payload: true });
  dispatch({
    type: 'setMarketplaceContractsFilterItems',
    payload: {
      pageIndex: reqParams.pageIndex,
      pageSize: reqParams.pageSize,
      offset: reqParams.offset,
      sortBy: reqParams.sortBy,
      sortDirection: reqParams.sortDirection,
    },
  });

  const newParams = {
    ...reqParams,
    status: reqParams.status.code,
    marketplace: 'aws',
  };

  getMarketplaceContractsTableDataApiRequest(
    prepareFilterTableRequestData(newParams, ['pageCount', 'pageIndex'])
  )
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({
          type: 'setMarketplaceContractsTableData',
          payload: data.items,
        });

        dispatch({
          type: 'setMarketplaceContractsFilterItems',
          payload: {
            pageCount: Math.ceil(data.itemsTotal / reqParams.pageSize),
          },
        });
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to get Marketplace contracts table data');
    })
    .finally(() => dispatch({ type: 'setLoadingMarketplaceContractsTableData', payload: false }));
}

const getMarketplaceAccountsConfRequest = () =>
  api.get(`${buildUrl('bousers')}/configuration/marketplace`);

export async function getMarketplaceAccountsConf(dispatch) {
  dispatch({ type: 'setMarketplaceContractsLoadPocConfig', payload: true });

  getMarketplaceAccountsConfRequest()
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({ type: 'setMarketplaceContractsStatuses', payload: data?.contractsStatuses });
        dispatch({ type: 'setMarketplaceContractsLoadPocConfig', payload: false });
      }
    })
    .catch((err) =>
      displayErrors(err, 'Failed trying to get configuration for Marketplace Contracts Page')
    );
}
