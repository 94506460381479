import { SingleSelectorDropDown } from '@redislabsdev/redislabs-ui-components';
import React, { useEffect } from 'react';
import * as S from './Cells.style';

export default function EditableSelectCell({
  value: initialValue,
  row,
  column: { id },
  getActionProps,
  tempRowData,
  handleInputChange,
  inputErrorsHandler,
  idKey,
}) {
  const { editMode } = getActionProps(row.original[idKey]);

  const handleSelectedOption = (item) => {
    inputErrorsHandler[id].validator.validate(item).catch((e) => {
      item && handleInputChange(id, item.value, e.message);
    });
    handleInputChange(id, item.value, '');
  };

  useEffect(() => {
    if (editMode) {
      handleInputChange(id, initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, editMode]);

  const optionList = [
    { id: 'yes', value: true, title: 'Yes' },
    { id: 'no', value: false, title: 'No' },
  ];
  return (
    <S.Cell editMode={editMode}>
      {editMode ? (
        <SingleSelectorDropDown
          data-role="dropdown-button"
          optionList={optionList}
          defaultValue={optionList.find((item) => item.value === tempRowData[id])}
          width="16rem"
          borderRadius
          borderColor="gray1"
          headerBorder
          getSelectedOption={(item) => handleSelectedOption(item)}
        />
      ) : (
        <S.TextWrapper>
          {optionList.find((item) => item.value === initialValue)?.title}
        </S.TextWrapper>
      )}
    </S.Cell>
  );
}
