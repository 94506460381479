import React from 'react';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { ResetIcon } from '@redislabsdev/redis-ui-icons';

import * as CS from '../../styles/common.style';
import { contractsFilterSectionLabels } from '../../constants/contractsPageConstants';

interface ClearAllAndApplyButtonsProps {
  clearAllCallback: () => {};
  applyButtonCallback: () => {};
}

const ClearAllAndApplyButtons: React.FC<ClearAllAndApplyButtonsProps> = (props) => {
  const { clearAllCallback, applyButtonCallback } = props;

  return (
      <CS.ClearAndApplyOneRowWrap>
          <Button data-testid="button--clear-all" variant="link" onClick={clearAllCallback}>
            <ResetIcon size="M" />
              <span>
                  {contractsFilterSectionLabels.clearAll}
              </span>
          </Button>
          <Button variant="secondary" data-testid="button--apply-filters" onClick={applyButtonCallback}>
              {contractsFilterSectionLabels.applyFilters}
          </Button>
      </CS.ClearAndApplyOneRowWrap>
  );
};

export default ClearAllAndApplyButtons;
