import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Input } from '@redislabsdev/redis-ui-components';
import { theme } from '@redislabsdev/redis-ui-styles';

import * as S from '../../contractsPage/contractPage/infoSection/infoSection.style';
import * as CS from '../../../styles/common.style';

import { updateStateOnInputType } from '../../../utils/common.utils';
import { errorsMessages } from '../../../utils/errorMessages';
import {
  SectionContent,
  SectionTitle,
  Separator,
  SpanDisplayFlexInline,
} from '../../../styles/commonAccountDetails.style';
import {
  getMarketplaceContractComment,
  getMarketplaceContractContactName,
  getMarketplaceContractCredit,
  getMarketplaceContractCreditConsumed,
  getMarketplaceContractCustomerEmail,
  getMarketplaceContractCustomerName,
  getMarketplaceContractEndDate,
  getMarketplaceContractInfoValidations,
  getMarketplaceContractMarketplace,
  getMarketplaceContractOnDemandChargeStart,
  getMarketplaceContractOverUsage,
  getMarketplaceContractRedislabsEmailList,
  getMarketplaceContractStartDate,
} from '../../../selectors/MarketplaceContractPageSelectors';

const McpInfoSection: React.FC = () => {
  const dispatch = useDispatch();
  const view = useRouteMatch('/marketplaceContracts/:id/view') || false;

  const marketplaceContractInfoValidation = useSelector(getMarketplaceContractInfoValidations);
  const customerName = useSelector(getMarketplaceContractCustomerName);
  const onDemandChargeStart = useSelector(getMarketplaceContractOnDemandChargeStart);
  const contactName = useSelector(getMarketplaceContractContactName);
  const customerEmail = useSelector(getMarketplaceContractCustomerEmail);
  const redislabsEmailList = useSelector(getMarketplaceContractRedislabsEmailList);
  const startDate = useSelector(getMarketplaceContractStartDate);
  const endDate = useSelector(getMarketplaceContractEndDate);
  const credit = useSelector(getMarketplaceContractCredit);
  const creditConsumed = useSelector(getMarketplaceContractCreditConsumed);
  const overUsage = useSelector(getMarketplaceContractOverUsage);
  const marketplace = useSelector(getMarketplaceContractMarketplace);
  const comment = useSelector(getMarketplaceContractComment);

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setMarketplaceContractInfo',
      dispatch,
      callback: () =>
        dispatch({
          type: 'setMarketplaceFormTouchedState',
          payload: true,
        }),
    });

  return (
    <S.InfoSection>
      <SectionTitle>Info</SectionTitle>
      <SectionContent>
        <div>
          <SpanDisplayFlexInline view={view}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="customerName">
              Customer name
              <CS.TooltipOverflowHidden>
                <CS.TooltipStyleOverride
                  tooltipContent={
                    <CS.TooltipTextWithTooltip>{customerName}</CS.TooltipTextWithTooltip>
                  }
                  placement="top"
                  trigger="hover"
                  textColor={theme.semantic.color.text.neutral700}
                >
                  <CS.TooltipViewValues>{customerName}</CS.TooltipViewValues>
                </CS.TooltipStyleOverride>
              </CS.TooltipOverflowHidden>
            </label>

            <label htmlFor="onDemandChargeStart">
              Estimated credit end
              <CS.ViewValues>
                {onDemandChargeStart && moment.utc(onDemandChargeStart).format('DD/MM/YYYY')}
              </CS.ViewValues>
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline view={view}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="contactName">
              Contact name
              <CS.TooltipOverflowHidden>
                <CS.TooltipStyleOverride
                  tooltipContent={
                    <CS.TooltipTextWithTooltip>{contactName}</CS.TooltipTextWithTooltip>
                  }
                  placement="top"
                  trigger="hover"
                  textColor={theme.semantic.color.text.neutral700}
                >
                  <CS.TooltipViewValues>{contactName}</CS.TooltipViewValues>
                </CS.TooltipStyleOverride>
              </CS.TooltipOverflowHidden>
            </label>

            <label htmlFor="customerEmail">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              Contact email
              {view ? (
                <CS.ViewValues>{customerEmail}</CS.ViewValues>
              ) : (
                <Input
                  autoComplete="new-contact-email"
                  id="customerEmail"
                  name="customerEmail"
                  data-testid="textinput--contact-email"
                  type="email"
                  value={customerEmail}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  error={
                    (!marketplaceContractInfoValidation.customerEmail &&
                      errorsMessages.customerEmail) as string
                  }
                  onBlur={({ target }) => {
                    dispatch({
                      type: 'setMarketplaceContractInfoValidation',
                      payload: { customerEmail: target.validity.valid },
                    });
                  }}
                  width="100%"
                  disabled={view}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                />
              )}
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline view={view}>
            <label htmlFor="redislabsEmailList">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              RedisLabs email list
              {view ? (
                <CS.TooltipOverflowHidden>
                  <CS.TooltipStyleOverride
                    tooltipContent={
                      <CS.TooltipTextWithTooltip>{redislabsEmailList}</CS.TooltipTextWithTooltip>
                    }
                    placement="top"
                    trigger="hover"
                    textColor={theme.semantic.color.text.neutral700}
                  >
                    <CS.TooltipViewValues>{redislabsEmailList}</CS.TooltipViewValues>
                  </CS.TooltipStyleOverride>
                </CS.TooltipOverflowHidden>
              ) : (
                <Input
                  autoComplete="new-contact-redislabsEmailList"
                  id="redislabsEmailList"
                  name="redislabsEmailList"
                  data-testid="textinput--contact-redislabsEmailList"
                  type="email"
                  value={redislabsEmailList}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  error={
                    (!marketplaceContractInfoValidation.redislabsEmailList &&
                      errorsMessages.redislabsEmailList) as string
                  }
                  onBlur={({ target }) => {
                    dispatch({
                      type: 'setMarketplaceContractInfoValidation',
                      payload: { redislabsEmailList: target.validity.valid },
                    });
                  }}
                  width="100%"
                  disabled={view}
                  multiple
                  pattern="([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)*(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*"
                />
              )}
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline>
            <label htmlFor="startDate">
              Start Date (UTC)
              <CS.ViewValues>{startDate?.format('DD/MM/YYYY')}</CS.ViewValues>
            </label>

            <label htmlFor="endDate">
              End Date (UTC)
              <CS.ViewValues>{endDate?.format('DD/MM/YYYY')}</CS.ViewValues>
            </label>
          </SpanDisplayFlexInline>
        </div>
        <Separator />
        <div>
          <SpanDisplayFlexInline>
            <label htmlFor="credit">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              Credit amount
              {view ? (
                <CS.ViewValues>{`$ ${credit || 0}`}</CS.ViewValues>
              ) : (
                <Input
                  autoComplete="new-credit-amount"
                  min={0}
                  max={100000000.0}
                  step={0.001}
                  id="credit"
                  name="credit"
                  data-testid="textinput--credit-amount"
                  type="number"
                  value={credit}
                  placeholder="$"
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  error={
                    (!marketplaceContractInfoValidation.credit && errorsMessages.credit) as string
                  }
                  onBlur={({ target }) => {
                    dispatch({
                      type: 'setMarketplaceContractInfoValidation',
                      payload: { credit: target.validity.valid },
                    });
                  }}
                  width="100%"
                  disabled={view}
                />
              )}
            </label>

            <label htmlFor="creditConsumed">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              Credit consumed
              <CS.ViewValues>{`$ ${creditConsumed || 0}`}</CS.ViewValues>
            </label>

            <label htmlFor="overUsage">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              Over usage
              <CS.ViewValues>{`$ ${overUsage || 0}`}</CS.ViewValues>
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline>
            <label htmlFor="marketplace">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              Marketplace
              <CS.ViewValues>{`${marketplace.toUpperCase()}`}</CS.ViewValues>
            </label>
          </SpanDisplayFlexInline>
          <SpanDisplayFlexInline>
            <label htmlFor="comments">
              Comments
              <CS.Textarea
                name="comment"
                id="comment"
                data-testid="textarea--comment"
                rows={6}
                disabled={view}
                value={comment || ''} // protection for null values
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            </label>
          </SpanDisplayFlexInline>
        </div>
      </SectionContent>
    </S.InfoSection>
  );
};

export default McpInfoSection;
